import { environment } from "./environments/environment";

export default function setupAxiosPlugin({ $axios }) {
  // Ensure $axios is an Axios instance
  if (!$axios || typeof $axios.interceptors === "undefined") {
    throw new Error("$axios must be an Axios instance");
  }

  // Set the base URL for Axios requests
  $axios.defaults.baseURL = environment.url.api;

  // Add a response interceptor to handle errors globally
  $axios.interceptors.response.use(
    (response) => response, // Pass through successful responses
    async (error) => {
      if (error.response && error.response.status === 401) {
        console.error("401 Error: Unauthorized. Validating token...");

        const token = localStorage.getItem("token_debug");

        if (token) {
          try {
            // Send a request to validate the token
            const debugResponse = await $axios.post(
              "/api/v1/users/debug_token",
              { token: token }
            );

            console.info("Token validation response:", debugResponse.data);
            // Handle the case where the token is valid if needed
          } catch (debugError) {
            console.error("Token validation failed. Logging out...");
            localStorage.removeItem("token");
            window.location.href = '/'; // Navigate to the login or home page
          }
        } else {
          console.warn("No token found. Redirecting to the login page...");
          window.location.href = '/';
        }
      }
     
    }
  );
}
