import axios from "axios";
import { Injectable } from "@angular/core";
import {
  IEmailUnsubscribeStatsCreate,
  IUserProfile,
  IUserProfileCreate,
  IUserProfileUpdate,
} from "interfaces";
import { IDiscountCode, IVat } from "interfaces/orders";
import { environment } from "../../../environments/environment";

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

let pixelID =
  "EACBEfUFZBFLwBAMOMnbhDmzKSOeZBPFGIS2FPv7T9XNJcqEuvrzsPIKafLBTOZCB5SyyBFusaFN3MAsIYVojQpQnK02fohIl79P9PZACsItrAmEzZAg8fX56iqnXEK3fx84vDjuG2iZA7ZB4XZArmZCZB51k8F7WTiUG02dMZAjl3W6AOK42l4PVGRm";

@Injectable()
export class UtilsService {
  constructor() {}

  async SendPartnerEmail(name: string, email: string, phone: string) {
    return axios.post(
      `/api/v1/utils/partner-email/${name}/${email}/${phone}`,
      authHeaders()
    );
  }

  async TestAccessToken() {
    return axios.get<IUserProfile>(
      `/api/v1/login/test-access-token`,
      authHeaders()
    );
  }

  async GetVAT(countryCode: string) {
    return axios.get<number>(`/api/v1/vat/${countryCode}`, authHeaders());
  }

  async GetDiscountCodes(code: string) {
    return axios.get<IDiscountCode>(
      `/api/v1/discount_codes/${code}`,
      authHeaders()
    );
  }

  async FacebookTag(data: any) {
    return axios.post(
      `https://graph.facebook.com/v16.0/749318913298052/events?access_token=${pixelID}`,
      data
    );
  }

  async UnsubscribeReason(data: IEmailUnsubscribeStatsCreate) {
    return axios.post<IEmailUnsubscribeStatsCreate>(
      `/api/v1/email_unsubscribe_stats/`,
      data
    );
  }

  async GenerateDashboardPdf(kitId: number, languageId: number) {
    return axios.post(
      `/api/v1/generate_pdf/${kitId}/${languageId}`,
      "",
      authHeaders()
    );
  }

  async GenerateDashboardPdfIprobio(kitId: number, languageId: number) {
    return axios.post(
      `/api/v1/generate_pdf_iprobio/${kitId}/${languageId}`,
      "",
      authHeaders()
    );
  }

  async GetDashboardPdfLinks(testkitId: number, languageCode: string) {
    try {
      const response = await axios.get<JSON>(
        `/api/v1/dashboard_pdf/?kit_id=${testkitId}&language_code=${languageCode}`,
        authHeaders()
      );

      console.log("Response: ", response.data); // Log the response for testing
      return response.data; // Return the single object
    } catch (error) {
      console.error("Error fetching supplement advice: ", error.message);
      return undefined; // Return undefined in case of error
    }
  }
}
