  import { HtmlTagDefinition } from "@angular/compiler";
  import { AfterViewChecked, Component, OnInit } from "@angular/core";
  import { ActivatedRoute } from "@angular/router";
  import { TranslateService } from "@ngx-translate/core";
  import { Chart } from "chart.js";
  import { RichTextFieldReadError } from "pdf-lib";
  import { setMaxListeners } from "process";
  import { pipe } from "rxjs";
  import { INutrientAdvice } from "../../../interfaces/advice";
  import { DietaryDashService } from "../api/calculations/dietarydash";
  import { AppComponent } from "../app.component";
  import { max } from "rxjs/operators";
  import { SubjectService } from '../api/test/subject';

  @Component({
    selector: "app-target-values",
    templateUrl: "./target-values.component.html",
    styleUrls: ["./target-values.component.css"],
  })
  export class TargetValuesComponent implements OnInit, AfterViewChecked {
    saltValueRanges: any[];
    constructor(
      private translate: TranslateService,
      public dietaryDashService: DietaryDashService,
      public app: AppComponent,
      private route: ActivatedRoute,
      private subjectService: SubjectService
    ) {}
    ngAfterViewChecked(): void {
      if (!this.loading && !this.chartInitialized) {
        this.createKcalPieChart();
        this.chartInitialized = true; // Prevent multiple initializations
      }
    }

    doughnutChart: any;
    chartInitialized = false;

    carbohydratesValue = 5;
    carbohydratesValueRanges: any = [];
    fatValue = 4;
    fatValueRanges: any = [];
    saturatedFatValue = 3;
    saturatedFatValueRanges: any = [];
    fiberValueRanges: any = [];
    proteinValue = 0; // Current protein value
    proteinValueRanges: string[] = []; // Ranges for the chart
    importantSurveyQuestions = [];
    totalKcal: number = 0;
    totalRecommendedKcal: number = 0;
    proteinOptimalValue = 0;
    fiberOptimalValue = 0;
    saltOptimalValue = 0;
    carbsOptimalValue = 0;
    fatOptimalValue = 0;
    satFatOptimalValue = 0;
    proteinChartValues = [];
    loading: boolean = true;
    userAge: number | null = null;

    saltObject: any;
    fibreObject: any;
    carbohydratesObject: any;
    fatObject: any;
    satFatObject: any;

    fiberChartValues: string[] = []; // Initialize as an empty array
    saltChartValues: string[] = [];
    proteinObject: any;
    fiberValue;
    saltValue;
    carbohydratesChartValues = [];
    fatChartValues = [];
    saturatedFatChartValues = [];
    originalFiberValue: number;
    originalSaltValue: number;

    saturatedFatTitle = "";
    fatTitle = "";
    carbsTitle = "";

    sectionToDisplay: any = 0;

    nutrientValues: INutrientAdvice[];

    // fiber intake values
    lowerFiber = 10;
    mediumFiber = 10;
    higherFiber = 10;

    // salt intake values
    lowerSalt = 0;
    mediumSalt = 6;
    higherSalt = 4;

    partnerImgSrc = "";

    gaugeColorVariants = {
      default: {
        normal: "#ad66d9",
        okay: "#cfbcf2",
        great: "#05e399",
        bad: "#fad408",
        aware: "#c8bfe7",
        bmi1: "#E5CEF3",
        bmi2: "#D3ADEB",
        bmi3: "#C28DE2",
      },
      iprobio: {
        normal: "#62b3b3",
        okay: "#b5d2d2",
        great: "#6fd06f",
        bad: "#f299a1",
        aware: "#339d9c69",
        bmi1: "#54adb426",
        bmi2: "#54adb440",
        bmi3: "#54adb47d",
      },
      patris_health: {
        normal: "#33ab9f",
        okay: "#81c784",
        great: "#66bb6a",
        bad: "#f44336",
        aware: "#b2dfdb",
        bmi1: "#33ab9f26",
        bmi2: "#33ab9f40",
        bmi3: "#33ab9f7d",
      },
      astrolab: {
        normal: "#0C6C74",
        okay: "#83C7B3",
        great: "#66BB6A",
        bad: "#D35247",
        aware: "#f0ca7c",
        bmi1: "#0C6C7426",
        bmi2: "#0C6C7440",
        bmi3: "#0C6C747D",
  
      },
    };
    selectedColors = this.gaugeColorVariants["default"];

    explanationText = "TARGET_NUTRIENTS.EXPLANATION_TEXT";
    calculateProteinChart(proteinObject: INutrientAdvice) {
      if (
        !proteinObject ||
        !proteinObject.range ||
        proteinObject.range.length === 0
      ) {
        console.warn("Invalid proteinObject data, using default values.");
        this.proteinValueRanges = ["33%", "33%", "34%"]; // Default fallback
        return;
      }

      const totalRange = proteinObject.range[proteinObject.range.length - 1][1];
      this.proteinValueRanges = proteinObject.range.map(
       (range) => `${Math.round(((range[1] - range[0]) / totalRange) * 100)}%`
      );
    }

    calculateDietaryFiberChart(fiberObject: INutrientAdvice) {
      if (!fiberObject || !fiberObject.range || fiberObject.range.length === 0) {
        console.warn("Invalid fiberObject data, using default values.");
        this.fiberChartValues = ["33%", "33%", "34%"]; // Default fallback
        return;
      }

      const totalRange = fiberObject.range[fiberObject.range.length - 1][1];
      this.fiberChartValues = fiberObject.range.map(
        (range) => `${Math.round(((range[1] - range[0]) / totalRange) * 100)}%`
      );
    }

    calculateSaltChart(saltObject: INutrientAdvice) {
      if (!saltObject || !saltObject.range || saltObject.range.length === 0) {
        console.warn("Invalid saltObject data, using default values.");
        this.saltChartValues = ["50%", "50%"]; // Default fallback
        return;
      }

      const totalRange = saltObject.range[saltObject.range.length - 1][1];
      this.saltChartValues = saltObject.range.map(
        (range) => `${Math.round(((range[1] - range[0]) / totalRange) * 100)}%`
      );
    }
    getProteinRange(): string {
      if (
        this.proteinObject &&
        this.proteinObject.range &&
        this.proteinObject.range[1] &&
        this.proteinObject.range[1].length > 1
      ) {
        const min = this.proteinObject.range[1][0];
        const max = this.proteinObject.range[1][1];
        return `${min.toFixed(0)} - ${max.toFixed(0)}`;
      }
      return "No data available";
    }
    
    async getNewGaugeNames() {
      this.translate
        .get("TARGET_NUTRIENTS.SATURATED")
        .subscribe((text: string) => {
          this.saturatedFatTitle = text;
        });
      this.translate.get("TARGET_NUTRIENTS.FAT").subscribe((text: string) => {
        this.fatTitle = text;
      });
      this.translate.get("TARGET_NUTRIENTS.CARBS").subscribe((text: string) => {
        this.carbsTitle = text;
      });
    }
    getCarbohydratesRange(): string {
      if (
        this.carbohydratesObject &&
        this.carbohydratesObject.range &&
        this.carbohydratesObject.range[1] &&
        this.carbohydratesObject.range[1].length > 1
      ) {
        const min = this.carbohydratesObject.range[1][0];
        const max = this.carbohydratesObject.range[1][1];
        return `${min.toFixed(0)} - ${max.toFixed(0)}`;
      }
      return "No data available";
    }
    getFatRange(): string {
      if (
        this.fatObject &&
        this.fatObject.range &&
        this.fatObject.range[1] &&
        this.fatObject.range[1].length > 1
      ) {
        const min = this.fatObject.range[1][0];
        const max = this.fatObject.range[1][1];
        return `${min.toFixed(0)} - ${max.toFixed(0)}`;
      }
      return "No data available";
    }
        
    async createGauges() {
      const confCarbohydrates = {
        type: "gauge",
        data: {
          datasets: [
            {
              data: this.carbohydratesValueRanges,
              value: this.carbohydratesValue,
              backgroundColor: [
                this.selectedColors["bad"],
                this.selectedColors["great"],
                this.selectedColors["bad"],
              ],
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.carbsTitle,
          },
          layout: {
            padding: {
              bottom: 30,
            },
          },
          needle: {
            // Needle circle radius as the percentage of the chart area width
            radiusPercentage: 2,
            // Needle width as the percentage of the chart area width
            widthPercentage: 3.2,
            // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
            lengthPercentage: 80,
            // The color of the needle
            color: "rgba(0, 0, 0, 1)",
          },
          valueLabel: {
            display: false,
            formatter: Math.round,
          },
        },
      };

      const confFat = {
        type: "gauge",
        data: {
          datasets: [
            {
              data: this.fatValueRanges,
              value: this.fatValue,
              backgroundColor: [
                this.selectedColors["bad"],
                this.selectedColors["great"],
                this.selectedColors["bad"],
              ],
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.fatTitle,
          },
          layout: {
            padding: {
              bottom: 30,
            },
          },
          needle: {
            // Needle circle radius as the percentage of the chart area width
            radiusPercentage: 2,
            // Needle width as the percentage of the chart area width
            widthPercentage: 3.2,
            // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
            lengthPercentage: 80,
            // The color of the needle
            color: "rgba(0, 0, 0, 1)",
          },
          valueLabel: {
            display: false,
            formatter: Math.round,
          },
        },
      };

      const confSaturatedFat = {
        type: "gauge",
        data: {
          datasets: [
            {
              data: this.saturatedFatValueRanges,
              value: this.saturatedFatValue,
              backgroundColor: [
                this.selectedColors["bad"],
                this.selectedColors["great"],
                this.selectedColors["bad"],
              ],
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.saturatedFatTitle,
          },
          layout: {
            padding: {
              bottom: 30,
            },
          },
          needle: {
            // Needle circle radius as the percentage of the chart area width
            radiusPercentage: 2,
            // Needle width as the percentage of the chart area width
            widthPercentage: 3.2,
            // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
            lengthPercentage: 80,
            // The color of the needle
            color: "rgba(0, 0, 0, 1)",
          },
          valueLabel: {
            display: false,
            formatter: Math.round,
          },
        },
      };

      const canvasCarbohydrates = document.getElementById(
        "carbohydrates"
      ) as HTMLCanvasElement;
      const ctxCarbohydrates = canvasCarbohydrates.getContext("2d");

      const canvasFat = document.getElementById("fat") as HTMLCanvasElement;
      const ctxFat = canvasFat.getContext("2d");

      const canvasSaturatedFat = document.getElementById(
        "saturated_fat"
      ) as HTMLCanvasElement;
      const ctxSaturatedFat = canvasSaturatedFat.getContext("2d");

      this.doughnutChart = new Chart(ctxCarbohydrates, confCarbohydrates);

      this.doughnutChart = new Chart(ctxFat, confFat);

      this.doughnutChart = new Chart(ctxSaturatedFat, confSaturatedFat);
    }

    async ngOnInit() {
      if (this.app.partnerVersion !== "") {
        this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
        this.partnerImgSrc = "-" + this.app.partnerVersion;
      }

      // this.hideNonPartnerElements();
      // this.explanationText =
      //   "TARGET_NUTRIENTS.EXPLANATION_TEXT_" +
      //   this.app.partnerVersion.toUpperCase();

      const testKitID = Number(this.route.snapshot.paramMap.get("id"));

      const response = await this.subjectService.GetSingleKit(testKitID);
      const subjectResponse = await this.subjectService.GetSubject(response.data["subject_id"]);
      console.log(response );
      console.log(subjectResponse );
      const dateOfBirth = new Date(subjectResponse.data.date_of_birth);
      const testDate = new Date(response.data["test_date"]);
      const timeDifferenceInMilliseconds = testDate.getTime() - dateOfBirth.getTime();
      const ageInDays = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));

      // You can then calculate age in years by dividing ageInDays by the number of days in a year (approximately 365.25)
      const ageInYears = Math.floor(ageInDays / 365.25);
      console.log(dateOfBirth);
      if (dateOfBirth) {
        this.userAge = ageInYears;
      } else {
        console.warn("Date of birth is not available for the user.");
      }

      if (sessionStorage.getItem("TargetNutValues-" + testKitID)) {
        this.nutrientValues = JSON.parse(
          sessionStorage.getItem("TargetNutValues-" + testKitID)
        );
      } else {
        const response = await await this.dietaryDashService.GetNutrientAdvice(
          testKitID
        );
        this.nutrientValues = response.data["nutrients"];
        this.totalKcal = response.data["total_kcal"];
        this.totalRecommendedKcal = response.data["total_recommended_kcal"];
        this.importantSurveyQuestions = response.data["question_ids"];
      }
      this.carbohydratesObject = this.nutrientValues.find(
        (x) => x.type === "carbohydrates"
      );
      this.fibreObject = this.nutrientValues.find((x) => x.type === "fibers");
      this.fatObject = this.nutrientValues.find((x) => x.type === "fat");
      this.satFatObject = this.nutrientValues.find(
        (x) => x.type === "saturated_fat"
      );
      this.proteinObject = this.nutrientValues.find((x) => x.type === "protein");
      this.saltObject = this.nutrientValues.find((x) => x.type === "salt");

      // Parse carbohydrate values and ranges
      this.carbohydratesValue = this.carbohydratesObject.value || 0;
      this.carbohydratesValueRanges = this.carbohydratesObject.range.map(
        (range) => range
      ) || [0,0];

      // Update specific values
      this.fiberValue = (this.fibreObject && this.fibreObject.value) || 0; // Default to 0 if null
      this.saturatedFatValue =
        (this.satFatObject && this.satFatObject.value) || 0; // Default to 0 if null
      this.carbohydratesValue =
        (this.carbohydratesObject && this.carbohydratesObject.value) || 0;
      this.fatValue = (this.fatObject && this.fatObject.value) || 0;
      this.proteinValue = (this.proteinObject && this.proteinObject.value) || 0;

      this.saturatedFatValueRanges =
        (this.satFatObject && this.satFatObject.range) || [];

      this.fatValueRanges = (this.fatObject && this.fatObject.range) || [];

      this.fiberValueRanges = (this.fibreObject && this.fibreObject.range) || [];
      this.saltValueRanges = (this.saltObject && this.saltObject.range) || [];
    
      
      console.log("Saturated Fat Ranges:", this.fatValueRanges);
      // Update ranges
      this.proteinValueRanges =
        (this.proteinObject && this.proteinObject.range) || [];
      this.proteinOptimalValue =
        this.proteinValueRanges && Array.isArray(this.proteinValueRanges[1])
          ? Number(this.proteinValueRanges[1][0]) || 0 // Convert to number
          : 0; // Default to 0 if invalid
      this.carbsOptimalValue =
        this.carbohydratesValueRanges &&
        Array.isArray(this.carbohydratesValueRanges[1])
          ? Number(this.carbohydratesValueRanges[1][0]) || 0 // Convert to number
          : 0; // Default to 0 if invalid
      this.fatOptimalValue =
        this.fatValueRanges && Array.isArray(this.fatValueRanges[1])
          ? Number(this.fatValueRanges[1][0]) || 0 // Convert to number
          : 0; // Default to 0 if invalidthis.carbsOptimalValue =
      this.satFatOptimalValue =
        this.saturatedFatValueRanges &&
        Array.isArray(this.saturatedFatValueRanges[0])
          ? Number(this.saturatedFatValueRanges[0][0]) || 0 // Convert to number
          : 0; // Default to 0 if invalidthis.carbsOptimalValue =

      this.fiberOptimalValue =
        this.fiberValueRanges && Array.isArray(this.fiberValueRanges[0])
          ? Number(this.fiberValueRanges[0][1]) || 0 // Convert to number
          : 0; // Default to 0 if invalidthis.carbsOptimalValue =
      this.saltOptimalValue =
        this.saltValueRanges && Array.isArray(this.saltValueRanges[0])
          ? Number(this.saltValueRanges[0][0]) || 0 // Convert to number
          : 0; // Default to 0 if invalidthis.carbsOptimalValue =
      this.carbohydratesValueRanges =
        (this.carbohydratesObject && this.carbohydratesObject.range) || [];

      this.fatValueRanges = (this.fatObject && this.fatObject.range) || [];
      this.saturatedFatValueRanges =
        (this.satFatObject && this.satFatObject.range) || [];
      
      this.saltValue = this.saltObject ? this.validateSaltValue(this.saltObject.value, this.saltValueRanges[1][1]) : 0;

      // Parse other nutrients similarly...
      this.calculateDietaryFiberChart(this.fibreObject);
      this.calculateSaltChart(this.saltObject);
      this.calculateCarbohydratesChart(this.carbohydratesObject);
      this.calculateFatChart(this.fatObject);
      this.calculateSaturatedFatChart(this.satFatObject);
      console.log(this.proteinObject);
      this.calculateProteinChart(this.proteinObject);
      this.createKcalPieChart();

      this.loading = false;
      // // TODO when changing language update values
      // this.translate
      //   .get("TARGET_NUTRIENTS.SATURATED")
      //   .subscribe((text: string) => {
      //     this.saturatedFatTitle = text;
      //     const confSaturatedFat = {
      //       type: "gauge",
      //       data: {
      //         datasets: [
      //           {
      //             data: this.saturatedFatValueRanges,
      //             value: this.saturatedFatValue,
      //             backgroundColor: [
      //               this.selectedColors["bad"],
      //               this.selectedColors["great"],
      //               this.selectedColors["bad"],
      //             ],
      //             borderWidth: 2,
      //           },
      //         ],
      //       },
      //       options: {
      //         responsive: true,
      //         title: {
      //           display: true,
      //           text: this.saturatedFatTitle,
      //         },
      //         layout: {
      //           padding: {
      //             bottom: 30,
      //           },
      //         },
      //         needle: {
      //           // Needle circle radius as the percentage of the chart area width
      //           radiusPercentage: 2,
      //           // Needle width as the percentage of the chart area width
      //           widthPercentage: 3.2,
      //           // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
      //           lengthPercentage: 80,
      //           // The color of the needle
      //           color: "rgba(0, 0, 0, 1)",
      //         },
      //         valueLabel: {
      //           display: false,
      //           formatter: Math.round,
      //         },
      //       },
      //     };

      //     const canvasSaturatedFat = document.getElementById(
      //       "saturated_fat"
      //     ) as HTMLCanvasElement;
      //     const ctxSaturatedFat = canvasSaturatedFat.getContext("2d");

      //     this.doughnutChart = new Chart(ctxSaturatedFat, confSaturatedFat);
      //   });

      // this.translate.get("TARGET_NUTRIENTS.FAT").subscribe((text: string) => {
      //   this.fatTitle = text;
      //   const confFat = {
      //     type: "gauge",
      //     data: {
      //       datasets: [
      //         {
      //           data: this.fatValueRanges,
      //           value: this.fatValue,
      //           backgroundColor: [
      //             this.selectedColors["bad"],
      //             this.selectedColors["great"],
      //             this.selectedColors["bad"],
      //           ],
      //           borderWidth: 2,
      //         },
      //       ],
      //     },
      //     options: {
      //       responsive: true,
      //       title: {
      //         display: true,
      //         text: this.fatTitle,
      //       },
      //       layout: {
      //         padding: {
      //           bottom: 30,
      //         },
      //       },
      //       needle: {
      //         // Needle circle radius as the percentage of the chart area width
      //         radiusPercentage: 2,
      //         // Needle width as the percentage of the chart area width
      //         widthPercentage: 3.2,
      //         // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
      //         lengthPercentage: 80,
      //         // The color of the needle
      //         color: "rgba(0, 0, 0, 1)",
      //       },
      //       valueLabel: {
      //         display: false,
      //         formatter: Math.round,
      //       },
      //     },
      //   };

      //   const canvasFat = document.getElementById("fat") as HTMLCanvasElement;
      //   const ctxFat = canvasFat.getContext("2d");

      //   this.doughnutChart = new Chart(ctxFat, confFat);
      // });

      // this.translate.get("TARGET_NUTRIENTS.CARBS").subscribe((text: string) => {
      //   this.carbsTitle = text;
      //   const confCarbohydrates = {
      //     type: "gauge",
      //     data: {
      //       datasets: [
      //         {
      //           data: this.carbohydratesValueRanges,
      //           value: this.carbohydratesValue,
      //           backgroundColor: [
      //             this.selectedColors["bad"],
      //             this.selectedColors["great"],
      //             this.selectedColors["bad"],
      //           ],
      //           borderWidth: 2,
      //         },
      //       ],
      //     },
      //     options: {
      //       responsive: true,
      //       title: {
      //         display: true,
      //         text: this.carbsTitle,
      //       },
      //       layout: {
      //         padding: {
      //           bottom: 30,
      //         },
      //       },
      //       needle: {
      //         // Needle circle radius as the percentage of the chart area width
      //         radiusPercentage: 2,
      //         // Needle width as the percentage of the chart area width
      //         widthPercentage: 3.2,
      //         // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
      //         lengthPercentage: 80,
      //         // The color of the needle
      //         color: "rgba(0, 0, 0, 1)",
      //       },
      //       valueLabel: {
      //         display: false,
      //         formatter: Math.round,
      //       },
      //     },
      //   };

      //   const canvasCarbohydrates = document.getElementById(
      //     "carbohydrates"
      //   ) as HTMLCanvasElement;
      //   const ctxCarbohydrates = canvasCarbohydrates.getContext("2d");

      //   this.doughnutChart = new Chart(ctxCarbohydrates, confCarbohydrates);
      // });

      // const coll = document.getElementsByClassName("collapsible");
      // for (let i = 0; i < coll.length; i++) {
      //   coll[i].addEventListener("click", function () {
      //     const content = this.nextElementSibling as HTMLElement;
      //     if (this.classList.contains("active")) {
      //       this.classList.remove("active");
      //       content.style.maxHeight = null;
      //     } else {
      //       let c;
      //       for (c = 0; c < coll.length; c++) {
      //         coll[c].classList.remove("active");
      //         const nextElmnt = coll[c].nextElementSibling as HTMLElement;
      //         nextElmnt.style.maxHeight = null;
      //       }
      //       this.classList.add("active");
      //       content.style.maxHeight = content.scrollHeight + "px";
      //     }
      //   });
      // }
    }
    private validateSaltValue(value: number, max: number): number {
      if (value < 0) return 0;
      if (value > max) return max;
      return value;
    }
    
    getDietaryFiberLevel() {
      const fiberValue = this.nutrientValues.find((x) => x.type === "fibers");
      if (fiberValue.value > 100) {
        fiberValue.value = 100;
      }
      if (fiberValue.value < 0) {
        fiberValue.value = 0;
      }
      return "calc(" + fiberValue.value + "% - 15px";
    }
    getProteinLevel() {
      const proteinObject = this.nutrientValues.find((x) => x.type === "protein");

      if (!proteinObject || !proteinObject.range) {
        console.warn("Invalid protein data");
        return "calc(0% - 15px)";
      }

      const totalRange = proteinObject.range[proteinObject.range.length - 1][1];
      const positionPercentage = (proteinObject.value / totalRange) * 100;

      console.log("Protein Needle Position:", positionPercentage);
      return `calc(${positionPercentage}% - 15px)`;
    }

    getSaltLevel() {
      const saltValue = this.nutrientValues.find((x) => x.type === "salt");
      if (!saltValue) {
        console.warn("Invalid salt data");
        return "calc(0% - 15px)";
      }
    
      const max = this.mediumSalt + this.higherSalt; // Calculate maximum range
      const validatedValue = this.validateSaltValue(saltValue.value, max);
      const cal = (validatedValue / max) * 100;
    
      console.log(`Validated Salt Value: ${validatedValue}`);
      return `calc(${cal}% - 15px)`;
    }
    
    calculateAge(birthDate: Date): number {
      console.log(birthDate);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      // Adjust age if the birthday hasn't occurred yet this year
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    }

    hideNonPartnerElements() {
      let elements = Array.from(
        document.getElementsByClassName("none-partner-elements")
      );
      for (const element of elements) {
        element.setAttribute("style", "display: none");
      }
    }

    toggleSectionToDisplay(section: number) {
      // If the same section is clicked again, close it
      this.sectionToDisplay = this.sectionToDisplay === section ? 0 : section;
    }
    calculateCarbohydratesChart(carbohydratesObject: INutrientAdvice) {
      if (
        !carbohydratesObject ||
        !carbohydratesObject.range ||
        carbohydratesObject.range.length === 0
      ) {
        console.warn("Invalid carbohydratesObject data, using default values.");
        this.carbohydratesValueRanges = ["33%", "33%", "34%"]; // Default fallback
        return;
      }

      const totalRange =
        carbohydratesObject.range[carbohydratesObject.range.length - 1][1];
      this.carbohydratesValueRanges = carbohydratesObject.range.map(
        (range) => `${Math.round(((range[1] - range[0]) / totalRange) * 100)}%`
      );

      console.log("Carbohydrates Chart Values:", this.carbohydratesValueRanges);
    }

    calculateFatChart(fatObject: INutrientAdvice) {
      if (!fatObject || !fatObject.range || fatObject.range.length === 0) {
        console.warn("Invalid fatObject data, using default values.");
        this.fatValueRanges = ["33%", "33%", "34%"]; // Default fallback
        return;
      }

      const totalRange = fatObject.range[fatObject.range.length - 1][1];
      this.fatValueRanges = fatObject.range.map(
        (range) => `${Math.round(((range[1] - range[0]) / totalRange) * 100)}%`
      );

      console.log("Fat Chart Values:", this.fatValueRanges);
    }

    calculateSaturatedFatChart(satFatObject: INutrientAdvice) {
      if (
        !satFatObject ||
        !satFatObject.range ||
        satFatObject.range.length === 0
      ) {
        console.warn("Invalid satFatObject data, using default values.");
        this.saturatedFatValueRanges = ["33%", "33%", "34%"]; // Default fallback
        return;
      }

      const totalRange = satFatObject.range[satFatObject.range.length - 1][1];
      this.saturatedFatValueRanges = satFatObject.range.map(
        (range) => `${Math.round(((range[1] - range[0]) / totalRange) * 100)}%`
      );

      console.log("Saturated Fat Chart Values:", this.saturatedFatValueRanges);
    }
    getFiberLevel() {
      const fiberObject = this.nutrientValues.find((x) => x.type === "fibers");

      if (!fiberObject || !fiberObject.range) {
        console.warn("Invalid fiber data");
        return "calc(0% - 15px)";
      }

      const totalRange = fiberObject.range[fiberObject.range.length - 1][1];
      let positionPercentage = (fiberObject.value / totalRange) * 100;

      
      // Adjusting for better alignment
      const adjustedPositionPercentage = Math.min(
        Math.max(positionPercentage, 0), // Ensure it's not less than 0
        100 // Ensure it doesn't exceed 100
      );

      console.log(
        "Fiber Needle Position (adjusted):",
        adjustedPositionPercentage
      );

      return `calc(${adjustedPositionPercentage}% - 15px)`;
    }

    getCarbohydratesLevel() {
      const carbohydratesObject = this.nutrientValues.find(
        (x) => x.type === "carbohydrates"
      );

      if (!carbohydratesObject || !carbohydratesObject.range) {
        console.warn("Invalid carbohydrates data");
        return "calc(0% - 15px)";
      }

      const totalRange =
        carbohydratesObject.range[carbohydratesObject.range.length - 1][1];
      const positionPercentage = (carbohydratesObject.value / totalRange) * 100;
      const adjustedPositionPercentage = Math.min(
        Math.max(positionPercentage, 0), // Ensure it's not less than 0
        100 // Ensure it doesn't exceed 100
      );

      return `calc(${adjustedPositionPercentage}% - 15px)`;
    }

    getFatLevel() {
      const fatObject = this.nutrientValues.find((x) => x.type === "fat");

      if (!fatObject || !fatObject.range) {
        console.warn("Invalid fat data");
        return "calc(0% - 15px)";
      }

      const totalRange = fatObject.range[fatObject.range.length - 1][1];
      const positionPercentage = (fatObject.value / totalRange) * 100;
      const adjustedPositionPercentage = Math.min(
        Math.max(positionPercentage, 0), // Ensure it's not less than 0
        100 // Ensure it doesn't exceed 100
      );

      return `calc(${adjustedPositionPercentage}% - 15px)`;
    }

    getSaturatedFatLevel() {
      const satFatObject = this.nutrientValues.find(
        (x) => x.type === "saturated_fat"
      );

      if (!satFatObject || !satFatObject.range) {
        console.warn("Invalid saturated fat data");
        return "calc(0% - 15px)";
      }

      const totalRange = satFatObject.range[satFatObject.range.length - 1][1];
      const positionPercentage = (satFatObject.value / totalRange) * 100;

      const adjustedPositionPercentage = Math.min(
        Math.max(positionPercentage, 0), // Ensure it's not less than 0
        100 // Ensure it doesn't exceed 100
      );

      return `calc(${adjustedPositionPercentage}% - 15px)`;
    }
    createKcalPieChart() {
      const canvasTargetNut = document.getElementById(
        "kcalPieChart"
      ) as HTMLCanvasElement;
      if (!canvasTargetNut) {
        console.warn("Canvas element not found: #kcalPieChart");
        return;
      }

      const ctxTargetNut = canvasTargetNut.getContext("2d");

      // Calculate recommended values based on totalRecommendedKcal
      const carbsRecommended = 0.55 * this.totalRecommendedKcal; // 55% for carbs
      const proteinRecommended = 0.2 * this.totalRecommendedKcal; // 20% for protein
      const fatRecommended = 0.25 * this.totalRecommendedKcal; // 30% for fat

      // Recommended intake values in kcal
      const recommendedValues = [
        carbsRecommended,
        fatRecommended,
        proteinRecommended,
      ].map((value) => Math.floor(value)); // Round to 1 decimal place

      const confTargetNut = {
        type: "doughnut",
        data: {
          labels: [
            this.translate.instant("DASHBOARD_OVERVIEW.CARBOHYDRATES"),
            this.translate.instant("DASHBOARD_OVERVIEW.FATS"),
            this.translate.instant("DASHBOARD_OVERVIEW.PROTEIN"),
          ],
          datasets: [
            {
              data: recommendedValues, // Recommended values
              backgroundColor: [
                this.selectedColors["bmi1"],
                this.selectedColors["bmi2"],
                this.selectedColors["bmi3"],
              ],
              borderWidth: 2,
              label: "Recommended Intake (kcal)",
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
        },
      };

      if (this.doughnutChart) {
        this.doughnutChart.destroy(); // Destroy existing chart
      }

      this.doughnutChart = new Chart(ctxTargetNut, confTargetNut);
    }
 
    
    
    
  }
  
