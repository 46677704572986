import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {IDashboardDietary, IEyyo} from "../../../interfaces/advice";
import {DietaryDashService} from "../api/calculations/dietarydash";
import {ActivatedRoute} from "@angular/router";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-fat-alarm',
  templateUrl: './fat-alarm.component.html',
  styleUrls: ['./fat-alarm.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FatAlarmComponent implements OnInit {

  constructor(public dietaryDashService: DietaryDashService, private route: ActivatedRoute, private translate: TranslateService) {
  }

  resultText = '';
  foodTableHTML = '';
  bottomTableHTML = '';
  thirdTableHTML = '';
  nutritionalAdvice = '';
  fatAlarm;
  range;
  dashBacteria: IEyyo;
  dietaryAdvice: IDashboardDietary[];

  async ngOnInit() {
    this.updateFoodTable();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateFoodTable();
      this.resultSampleText();
    });
    this.dashBacteria = JSON.parse(sessionStorage.getItem('Dashboard'));
    console.log(this.dashBacteria);
    this.fatAlarm = this.dashBacteria.graphs.find(x => x.graph === 'Fat Alarm').value;
    this.range = this.dashBacteria.graphs.find(x => x.graph === 'Fat Alarm').range;
    const testKitID = Number(this.route.snapshot.paramMap.get('id'));
    this.dietaryAdvice = await (await this.dietaryDashService.GetDietaryAdvice(testKitID, 'Fat alarm',
      this.translate.getDefaultLang())).data;
    this.resultSampleText();
  }


  getBacteriaLevel() {
    const dif = (this.range[1] - this.range[0]) / 70 * 15;
    const refUp = this.range[1] + dif; // potential for gas production
    const refLow = this.range[0] - dif; // potential for gas production
    console.log('value:');
    console.log(this.fatAlarm);
    let cal = (this.fatAlarm - refLow) / (refUp - refLow) * 100;
    if (cal < 0) {
      cal = 0;
    }
    if (cal > 100) {
      cal = 100;
    }
    cal = 100 - cal;
    return 'calc(' + cal + '% - 15px)';
  }

  resultSampleText() {
    if (this.fatAlarm > this.range[1]) {
      this.createResultString('FAT_ALARM.BE_AWARE_RESULT');
      return 'be_aware';
    }
    if (this.fatAlarm < this.range[0] || this.fatAlarm === 0) {
      this.createResultString('FAT_ALARM.GREAT_RESULT');
      return 'great';
    }
    this.createResultString('FAT_ALARM.NORMAL_RESULT');
    return 'normal';
  }

  createResultString(tag: string) {
    this.translate.get(tag).subscribe((text: string) => {
      this.resultText = text;
    });
  }

  updateFoodTable() {
    this.translate.get('FOOD_ITEM_LIMIT_TABLE.HTML').subscribe((text: string) => {
      this.foodTableHTML = text;
    });
    this.translate.get('FAT_ALARM.BOTTOM_TABLE_HTML').subscribe((text: string) => {
      this.bottomTableHTML = text;
    });
    this.translate.get('FAT_ALARM.TABLE_THREE').subscribe((text: string) => {
      this.thirdTableHTML = text;
    });
    this.translate.get('FAT_ALARM.NUTRITION_ADVICE').subscribe((text: string) => {
      this.nutritionalAdvice = text;
    });
  }
}
