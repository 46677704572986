import { Component, OnInit } from "@angular/core";
import * as Survey from "survey-angular";
import { Model, SurveyNG, NumericValidator } from "survey-angular";
import { SurveyService } from "../api/survey/survey";
import { ActivatedRoute, Router } from "@angular/router";
import { SubjectService } from "../api/test/subject";
import { IUserSurveyAnswer } from "../../../interfaces/surveys";
import { UtilityService } from "../utility.service";
import { Converter } from "showdown";
import { TranslateService } from "@ngx-translate/core";
import * as surveyData from "./survey-data.json";
// import 'survey-angular/modern.css';
Survey.StylesManager.applyTheme("bootstrap");

@Component({
  selector: "survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.css"],
})
export class SurveyComponent implements OnInit {
  constructor(
    private surveyService: SurveyService,
    private testkitService: SubjectService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilityService
  ) {}

  userAnswers: IUserSurveyAnswer[] = [];
  surveyJSON;
  testResults;
  testSubject;
  testResultID;
  survey;
  questions;

  async ngOnInit() {
    this.testResultID = Number(this.route.snapshot.paramMap.get("id"));
    this.testResults = (
      await this.testkitService.GetTestKit(this.testResultID)
    ).data;

    const myCss = {
      navigation: {
        complete: "sv_complete_btn",
        prev: "sv_prev_btn",
        next: "sv_next_btn",
      },
      root: "sv_main sv_bootstrap_css",
      container: "sv_container",
      progressTop: "progress center-block mx-auto mb-4",
      progressBar: "progress-bar",
      progressText: "sv-hidden",
      row: "sv_row",
      question: {
        mainRoot: "sv_qstn",
      },
      rating: {
        item: "sv-rating-default",
        selected: "sv-rating-selected",
      },
    };

    this.testSubject = (
      await this.testkitService.GetSubject(this.testResults.subject_id)
    ).data;

    // Select the correct survey JSON
    if (
      this.testResults.uid_testkit &&
      (this.testResults.uid_testkit.indexOf("SH-A-") !== -1 ||
        this.testResults.uid_testkit.indexOf("SS-A-") !== -1)
    ) {
      this.surveyJSON = (await this.surveyService.GetSurveyById(11)).data;
      this.questions = surveyData["11"];
    } else if (
      this.testResults.uid_testkit &&
      this.testResults.uid_testkit.indexOf("SK-A-") !== -1
    ) {
      this.surveyJSON = (await this.surveyService.GetSurveyById(12)).data;
      this.questions = surveyData["12"];
    } else {
      this.surveyJSON = (await this.surveyService.GetSurveyById(14)).data;
      this.questions = surveyData["14"];
    }

    // Initialize the survey model
    this.surveyJSON.showCompletedPage = false;
    this.survey = new Model(this.surveyJSON);

    let converter = new Converter();
    this.survey.onTextMarkdown.add(function (survey, options) {
      let str = converter.makeHtml(options.text);
      str = str.replace("<p>", "").replace("</p>", "");
      options.html = str;
    });

    // Add runtime validation for answers
    this.survey.onValueChanged.add((sender, options) => {
      console.log(
        `Value changed for: ${options.name}, New Value: ${options.value}`
      );

      const questionName = options.name;
      const value = options.value;

      const question = sender.getQuestionByName(questionName);

      if (questionName.includes("height")) {
        const isValid = this.validateHeight(value);
        console.log(`Validating height: ${value}, Valid: ${isValid}`);
        if (!isValid) {
          sender.setValue(questionName, null); // Clear invalid value
          if (
            !question.errors.some(
              (error) =>
                error.text ===
                this.translate.instant("SURVEY.HEIGHT_RESTRICTION_ERROR")
            )
          ) {
            question.addError(
              this.translate.instant("SURVEY.HEIGHT_RESTRICTION_ERROR")
            );
          }
        } else {
          question.clearErrors();
        }
      }

      if (questionName.includes("waist")) {
        const isValid = this.validateWaist(value);
        console.log(`Validating waist: ${value}, Valid: ${isValid}`);
        if (!isValid) {
          sender.setValue(questionName, null); // Clear invalid value
          if (
            !question.errors.some(
              (error) =>
                error.text ===
                this.translate.instant("SURVEY.WAIST_RESTRICTION_ERROR")
            )
          ) {
            question.addError(
              this.translate.instant("SURVEY.WAIST_RESTRICTION_ERROR")
            );
          }
        } else {
          question.clearErrors();
        }
      }

      if (questionName.includes("hip")) {
        const isValid = this.validateHip(value);
        console.log(`Validating hip: ${value}, Valid: ${isValid}`);
        if (!isValid) {
          sender.setValue(questionName, null); // Clear invalid value
          if (
            !question.errors.some(
              (error) =>
                error.text ===
                this.translate.instant("SURVEY.HIP_RESTRICTION_ERROR")
            )
          ) {
            question.addError(
              this.translate.instant("SURVEY.HIP_RESTRICTION_ERROR")
            );
          }
        } else {
          question.clearErrors();
        }
      }

      if (questionName.includes("weight")) {
        const isValid = this.validateWeight(value);
        console.log(`Validating weight: ${value}, Valid: ${isValid}`);
        if (!isValid) {
          sender.setValue(questionName, null); // Clear invalid value
          if (!question.errors.some((error) => error.text === this.translate.instant("SURVEY.WEIGHT_RESTRICTION_ERROR"))) {
            question.addError(
              this.translate.instant("SURVEY.WEIGHT_RESTRICTION_ERROR")
            );
          }
        } else {
          question.clearErrors();
        }
      }
      if (questionName.includes("name")) {
        const isValid = /^[a-zA-Z\s]+$/.test(value); // Check if value contains only letters and spaces
        console.log(`Validating name: ${value}, Valid: ${isValid}`);
        if (!isValid) {
          sender.setValue(questionName, null); // Clear invalid value
          if (
            !question.errors.some(
              (error) =>
                error.text === this.translate.instant("SURVEY.NAME_RESTRICTION_ERROR")
            )
          ) {
            question.addError(
              this.translate.instant("SURVEY.NAME_RESTRICTION_ERROR")
            );
          }
        } else {
          question.clearErrors();
        }
      }
    });

    this.survey.css = myCss;
    this.survey.locale = localStorage.getItem("language");
    this.survey.onCurrentPageChanged.add(this.doOnCurrentPageChanged);
    SurveyNG.render("surveyElement", { model: this.survey });

    this.translateUi();
  }
  private isValidValue(value: any, integerOnly: boolean = false): boolean {
    console.log(value);
    if (value === null || value === undefined || isNaN(value)) {
      console.log(`Invalid value: ${value} (not a number or null/undefined)`);
      return false; // Ensure the value is a number and not null/undefined
    }
    if (typeof value === "string" && value.includes(",")) {
      console.log(`Invalid value: ${value} (contains a comma)`); // Explicitly log and reject commas
      return false; // Disallow commas entirely
    }
    if (integerOnly && !Number.isInteger(Number(value))) {
      console.log(`Invalid value: ${value} (not an integer)`);
      return false; // Ensure the value is an integer if required
    }
    return true;
  }

  validateWeight(value: any): boolean {
    console.log(`Validating weight: ${value}`);
    if (!this.isValidValue(value, false)) return false; // Ensure number validation without allowing commas
    const numericValue = Number(value);
    return numericValue >= 10 && numericValue <= 150;
  }

  validateHeight(value: any): boolean {
    console.log(`Validating height: ${value}`);
    if (!this.isValidValue(value, true)) return false; // Ensure integer-only validation
    const numericValue = Number(value);
    return numericValue >= 120 && numericValue <= 250;
  }

  validateWaist(value: any): boolean {
    console.log(`Validating waist: ${value}`);
    if (!this.isValidValue(value, true)) return false; // Ensure integer-only validation
    const numericValue = Number(value);
    return numericValue >= 50 && numericValue <= 150;
  }

  validateHip(value: any): boolean {
    console.log(`Validating hip: ${value}`);
    if (!this.isValidValue(value, true)) return false; // Ensure integer-only validation
    const numericValue = Number(value);
    return numericValue >= 40 && numericValue <= 200;
  }

  doOnCurrentPageChanged(survey) {
    document.getElementById("surveyComplete").style.display = survey.isLastPage
      ? "inline"
      : "none";
    const elements = document.getElementsByClassName("sv_complete_btn");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  async finishSurvey() {
    document.getElementById("surveyComplete").style.display = "none";
    await this.surveyService.ClearSurvey(this.testResultID);
    this.survey.doComplete();
    const data = this.survey.data;
    for (const key in data) {
      console.log(key);
      console.log(data[key]);
      // In case user selects "Other" option
      if (data[key] === "other") {
        console.log("skiped question");
        continue;
      }
      if (Array.isArray(data[key])) {
        console.log(data[key]);
        for (let i = 0; i < Object.keys(data[key]).length; i++) {
          let question = this.questions.filter((obj) => {
            return obj.name === key;
          });
          console.log(
            "array question " + question[0].id + " " + question[0].name
          );
          if (data[key][i] === "other") {
            console.log("skiped array question");
            continue;
          }
          const userAnswer: IUserSurveyAnswer = {
            testResultID: this.testResults.id,
            questionID: question[0].id,
            answerID: data[key][i],
            openAnswer: null,
          };
          this.userAnswers.push(userAnswer);
        }
      } else {
        let question = this.questions.filter((obj) => {
          return obj.name === key;
        });
        if (question[0].open) {
          const userAnswer: IUserSurveyAnswer = {
            testResultID: this.testResults.id,
            questionID: question[0].id,
            answerID: null,
            openAnswer: data[key],
          };
          this.userAnswers.push(userAnswer);
        } else {
          const userAnswer: IUserSurveyAnswer = {
            testResultID: this.testResults.id,
            questionID: question[0].id,
            answerID: data[key],
            openAnswer: null,
          };
          this.userAnswers.push(userAnswer);
        }
      }
    }
    console.log(this.survey.data);
    console.log("test");
    console.log(this.userAnswers);
    const response = await this.surveyService.SubmitSurvey(this.userAnswers);
    this.utils.showSuccess("SURVEY_SUCCESS", "SURVEY_SUCCESS");
    await this.router.navigate(["/account-category/1"]);
  }

  translateUi() {
    if (this.translate.currentLang == "sk") {
      const targetNode = document.getElementById("surveyElement");
      const config = { attributes: true, childList: true, subtree: true };
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.target.textContent == "Other (describe)") {
            mutation.target.textContent = "Iné (popíšte)";
          }
        });
      });
      observer.observe(targetNode, config);

      let nextBtn = document.getElementsByClassName(
        "sv_next_btn"
      )[0] as HTMLInputElement;
      let prevBtn = document.getElementsByClassName(
        "sv_prev_btn"
      )[0] as HTMLInputElement;

      nextBtn.value = "Ďalej";
      prevBtn.value = "Späť";
    }
  }

  extractQuestionsFromSurvey(surveyJSON) {
    let extractedQuestions = [];

    // Iterate over pages and extract questions
    surveyJSON.pages.forEach((page) => {
      page.questions.forEach((question) => {
        extractedQuestions.push({
          id: question.id,
          name: question.name,
          open: question.hasOther || question.type === "text", // Add conditions for open questions
        });
      });
    });
    console.log("hello");
    console.log(extractedQuestions);
    return extractedQuestions;
  }
}
