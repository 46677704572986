import { Component, OnInit, Input } from "@angular/core";
import { AppComponent } from "../../app/app.component";

@Component({
  selector: "app-comparison-bmi",
  templateUrl: "./comparison-bmi.component.html",
  styleUrls: ["./comparison-bmi.component.css"],
})
export class ComparisonBmiComponent implements OnInit {
  @Input() data: any = {};

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: ["iprobio_blue", "iprobio_blue_light", "iprobio_green", "iprobio_red", "iprobio_blue_dark"],
    patris_health: [
      "patris_health_blue",
      "patris_health_green",
      "patris_health_purple",
      "patris_health_yellow",
      "patris_health_light_purple"
    ],
  }
  legendColors: any = this.legendColorsVariants['default'];
  hipWaistRanges: { min: number; max: number; className: string; label: string }[] = [];
  isFemaleConsistent: boolean | null = null; // True, False, or Mixed
  femaleData: any[] = [];
  maleData: any[] = [];

  isFemale: boolean = false;
  constructor(public app: AppComponent) {}

  ngOnInit() {
    // Pick correct marker colors
    if (this.app.partnerVersion != "") {
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
    }

    // Determine if isFemale is consistent across kits
    const isFemaleValues = this.data.map((kit: any) => kit.isFemale);
    const uniqueValues = Array.from(new Set(isFemaleValues));

    if (uniqueValues.length === 1) {
      this.isFemaleConsistent = uniqueValues[0] === true; 
    } else {
      this.isFemaleConsistent = null; // Mixed genders
    }

    // Filter data for male and female kits
    this.femaleData = this.data.filter((kit: any) => kit.isFemale);
    this.maleData = this.data.filter((kit: any) => !kit.isFemale);

    // Set hip-waist ranges
    this.setHipWaistRanges();
  }
  
  setHipWaistRanges() {
    // Define ranges explicitly
    if (this.isFemaleConsistent !== null) {
      this.hipWaistRanges = this.isFemaleConsistent
        ? [
            { min: 0, max: 0.79, className: "bmi-2", label: "BMI.LOW_RISK" },
            { min: 0.8, max: 0.84, className: "bmi-1", label: "BMI.MODERATE_RISK" },
            { min: 0.85, max: 10, className: "bmi-4", label: "BMI.HIGH_RISK" },
          ]
        : [
            { min: 0, max: 0.95, className: "bmi-2", label: "BMI.LOW_RISK" },
            { min: 0.96, max: 0.99, className: "bmi-1", label: "BMI.MODERATE_RISK" },
            { min: 1.0, max: 10, className: "bmi-4", label: "BMI.HIGH_RISK" },
          ];
    }
  }

  getHipWaistRanges(isFemale: boolean) {
    return isFemale
      ? [
          { min: 0, max: 0.79, className: "bmi-2", label: "BMI.LOW_RISK" },
          { min: 0.8, max: 0.84, className: "bmi-1", label: "BMI.MODERATE_RISK" },
          { min: 0.85, max: 10, className: "bmi-4", label: "BMI.HIGH_RISK" },
        ]
      : [
          { min: 0, max: 0.95, className: "bmi-2", label: "BMI.LOW_RISK" },
          { min: 0.96, max: 0.99, className: "bmi-1", label: "BMI.MODERATE_RISK" },
          { min: 1.0, max: 10, className: "bmi-4", label: "BMI.HIGH_RISK" },
        ];
  }

  
  sharesValue(item: any, i: any) {
    let flip = false;
    this.data.forEach((element: any, index: any) => {
      if (element !== item) {
        if (element.bmi >= item.bmi) {
          if (element.bmi - item.bmi < 2) {
            if (i > index) flip = true;
          }
        }
        if (item.bmi >= element.bmi) {
          if (item.bmi - element.bmi < 2) {
            if (i > index) flip = true;
          }
        }
      }
    });

    return flip;
  }

  getBMILevel(val: any) {
    if (!val) {
      val = "Undefined";
    }

    const bmiThresholds = [0, 18.5, 25, 30, 40]; // BMI thresholds
    const graphThresholds = [0, 25, 50, 75, 100]; // Graph positions for each range
  
    for (let i = 1; i < bmiThresholds.length; i++) {
      if (val <= bmiThresholds[i]) {
        const position =
          graphThresholds[i - 1] +
          ((val - bmiThresholds[i - 1]) /
            (bmiThresholds[i] - bmiThresholds[i - 1])) *
            (graphThresholds[i] - graphThresholds[i - 1]);
  
        console.log(`BMI Value: ${val}, Calculated Position: ${position}%`);
        return `calc(${position}% - 15px)`; // Adjust for needle width
      }
    }
  
    console.log(`BMI Value exceeds thresholds: ${val}`);
    return "calc(100% - 15px)";
  }
  getWHRLevel(val: number, isFemale: boolean): string {
    console.log("WHR Value:", val, "isFemale:", isFemale);
  
    // Define ranges dynamically based on gender
    const ranges = isFemale
      ? [
          { min: 0, max: 0.79, className: "bmi-2", label: "BMI.LOW_RISK" },
          { min: 0.8, max: 0.84, className: "bmi-1", label: "BMI.MODERATE_RISK" },
          { min: 0.85, max: 1.6, className: "bmi-4", label: "BMI.HIGH_RISK" },
        ]
      : [
          { min: 0, max: 0.95, className: "bmi-2", label: "BMI.LOW_RISK" },
          { min: 0.96, max: 0.99, className: "bmi-1", label: "BMI.MODERATE_RISK" },
          { min: 1.0, max: 1.90, className: "bmi-4", label: "BMI.HIGH_RISK" },
        ];
  
    if (!val) return "calc(0% - 15px)";
  
    const totalWidth = 100; // Total chart width in percentage
    const rangeWidth = totalWidth / ranges.length;
  
    // Find the appropriate range for the value
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      if (val >= range.min && val <= range.max) {
        const offsetWithinRange =
          ((val - range.min) / (range.max - range.min)) * rangeWidth;
        const position = i * rangeWidth + offsetWithinRange;
        return `calc(${position}% - 15px)`; // Adjust for needle width
      }
    }
  
    return "calc(0% - 15px)"; // Default fallback
  }
  sharesValueHWR(item: any, index: number): boolean {
    let flip = false;
    this.data.forEach((element, idx) => {
      if (element !== item) {
        const difference = Math.abs(element.whr - item.whr);
        if (difference < 0.02 && idx < index) {
          flip = true;
        }
      }
    });
    return flip;
  }

  get hasValidBMIData(): boolean {
    return this.data.some((item: any) => item.bmi !== null);
  }
  
  get hasValidWHRFemaleData(): boolean {
    return this.femaleData.some((item: any) => item.whr !== null);
  }
  
  get hasValidWHRMaleData(): boolean {
    return this.maleData.some((item: any) => item.whr !== null);
  }
  
}
