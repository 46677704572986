import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms'; // Import Validators
import { ISubject } from 'interfaces/tests';
import { SubjectService } from '../api/test/subject';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from "../utility.service";

@Component({
  selector: 'app-subject-details',
  templateUrl: './subject-details.component.html',
  styleUrls: ['./subject-details.component.css']
})
export class SubjectDetailsComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private subjectService: SubjectService,
    private utils: UtilityService,
    private activatedRoute: ActivatedRoute
  ) {}

  title = 'Profile photo';
  subject: ISubject;
  private sub: any;
  id: number;

  // Update form group to include first_name and last_name
  subjectDetailsForm = this.formBuilder.group({
    first_name: ['', Validators.required], // Add Validators for required fields
    last_name: ['', Validators.required],  // Add Validators for required fields
    date_of_birth: ['', Validators.required]
  });

  async ngOnInit() {
    // Fetch subject ID from route parameters
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
    });
  
    // Fetch subject data and initialize the form
    const response = await this.subjectService.GetSubject(this.id);
    this.subject = response.data;
  
    // Split full_name into first_name and last_name
    const nameParts = this.subject.full_name.split(' ');
    const firstName = nameParts[0]; // First word as the first name
    const lastName = nameParts.slice(1).join(' '); // Everything else as the last name
  
    // Initialize form values
    this.subjectDetailsForm.get('first_name').setValue(firstName);
    this.subjectDetailsForm.get('last_name').setValue(lastName);
    this.subjectDetailsForm.get('date_of_birth').setValue(this.subject.date_of_birth);
    localStorage.setItem('date_of_birth', this.subject.date_of_birth);
  }
  

  async onSubmit() {
    if (this.subjectDetailsForm.invalid) {
      this.utils.showWarning('PAYMENT_USER_REGISTRATION_ERROR', 'PAYMENT_USER_REGISTRATION_ERROR');
      return;
    }
  
    // Combine first_name and last_name into full_name
    const fullName = `${this.subjectDetailsForm.get('first_name').value} ${this.subjectDetailsForm.get('last_name').value}`.trim();
  
    const updatedSubject: ISubject = {
      full_name: fullName,
      gender: this.subject.gender,
      date_of_birth: this.subjectDetailsForm.get('date_of_birth').value
    };
  
    try {
      await this.subjectService.UpdateSubject(updatedSubject, this.id);
      this.utils.showSuccess('UPDATE_USER_SUBJECT_SUCCESS', 'UPDATE_USER_SUBJECT_SUCCESS');
    } catch (error) {
      this.utils.showError('UPDATE_USER_SUBJECT_ERROR', 'UPDATE_USER_SUBJECT_ERROR');
    }
  }
  
}
