import { Theme } from './symbols';

export const astrolabTheme: Theme = {
  name: 'astrolab',
  properties: {
    '--color-profile-tab': '#0C6C74',  // Main theme color
    '--navbar-img': 'url("assets/astrolab-logo.png")',
    '--footer-img': 'url("assets/astrolab-logo.png")',
    '--navbar-color': '#0C6C74',  // Darker shade for nav elements
    '--navbar-light-color': '#ffffff',  // Light text color
    '--conditions-color': '#83C7B3',  // Complementary lighter tone
    '--navigation-color': '#83C7B3',  // Matching conditions color
    '--contact-color': '#83C7B3',  // Accent for contact areas
    '--copyrights-color': '#EE7A76',  // Secondary accent for text
    '--btn-dark-color': 'linear-gradient(135deg, #83C7B3 0%, #0C6C74 100%)',  // Gradient with main and accent colors
    '--btn-light-color': '#83C7B3',  // Semi-transparent light green
    '--btn-light-color-active': '#83C7B3',  // Full opacity of light green
    '--table-header-color': '#0C6C74',  // Matches primary color
    '--kit-code-btn-color': '#0C6C74',  // Accent color for call-to-actions
    '--btn-fill-in-color': '#83C7B3',  // Light complementary tone
    '--btn-fill-in-color-active': '#83C7B3',
    '--food-diary-color': '#D35247',  // High-visibility red for important actions
    '--food-diary-color-light': '#F5F7FA',  // Neutral light background
    '--survey-color': '#0C6C74',
    '--survey-color-complete': '#83C7B3',  // Lighter green for completed items
    '--dashboard-container': '#F5F7FA',  // Neutral container background
    '--dashboard-container-hover': '#0C6C7454',  // Slightly transparent hover effect
    '--sub-dropdown-color': '#0C6C74',
    '--dropdown-box': '#0C6C7426',  // Very light blue-green for dropdowns
    '--compound-container-color': '#B6D6D3',  // Muted complementary color
    '--normal-color': '#0C6C74',  // Default soft green
    '--okay-color': '#83C7B3',  // Lighter green for positive status // Lighter green for positive status
    '--less-great-color': '#85D88A',
    '--great-color': '#66BB6A',  // Bright green for excellence
    '--bad-color': '#D35247',  // Red for errors or alerts
    '--aware-color': '#f0ca7c',  // Muted green for awareness
    '--warning-color': '#F44336',  // Red for critical warnings
    '--warning-color-fibre': '#F44336',  // Same red for fiber warnings
    '--take-home-bubble-color': '#0C6C74',  // Matches main theme
    '--rectangle-color': '#0C6C7426',  // Light rectangle background
    '--description-color': '#F5F7FA',  // Neutral text background
    '--protein-color': '#83C7B3',  // Matches complementary tone
    '--bmi-1-color': '#f0ca7c',  // Gradual primary accent
    '--bmi-2-color': '#66BB6A',  // Brighter accent
    '--bmi-3-color': '#f0ca7c',  // Higher opacity for middle accent
    '--bmi-4-color': '#D35247',  // Strong for highest BMI
    '--tg-1-color': '#0C6C7426',
    '--tg-2-color': '#0C6C7440',
    '--tg-3-color': '#0C6C747D',
    '--recomendations-color': '#0C6C74',  // Matches main theme
    '--img-placeholder-color': '#0C6C74',  // Consistent light background
    '--fibre-color': '#83C7B38F',  // Light semi-transparent tone
    '--green-color': '#66BB6A',
    '--yellow-color': '#D35247',
    '--lessnormal-color': '#83C7B3',
    '--morenormal-color': '#83C7B3',
    '--text-color': '#000000',
    '--footer-justify-content': 'space-between',
    '--button-light-1-color': '#0C6C7426',
  }
};