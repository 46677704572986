import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../api/calculations/dashboard";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { AppComponent } from "../../app/app.component";
import { SurveyService } from "../api/survey/survey";

@Component({
  selector: "app-kit-comparison",
  templateUrl: "./kit-comparison.component.html",
  styleUrls: ["./kit-comparison.component.css"],
})
export class KitComparisonComponent implements OnInit {
  newSurvey: boolean;
  isFemale: boolean;
  constructor(
    public dashboardService: DashboardService,
    public dietaryDashService: DietaryDashService,
    public app: AppComponent
  ) {}

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: [
      "iprobio_blue",
      "iprobio_blue_light",
      "iprobio_green",
      "iprobio_red",
      "iprobio_blue_dark",
    ],
    patris_health: [
      "patris_health_blue",
      "patris_health_green",
      "patris_health_purple",
      "patris_health_yellow",
      "patris_health_light_purple"
    ],
  };
  legendColors: any = this.legendColorsVariants["default"];

  kits: any = JSON.parse(sessionStorage.getItem("ComparisonKits"));
  kitsData: any = [];
  isDataLoaded: boolean = false;

  ngOnInit() {
    // Pick correct marker colors
    if (this.app.partnerVersion != "") {
      console.log(this.app.partnerVersion);
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
    }
    this.getKitData();
  }

  async getKitData() {
    const kitPromises = this.kits.map(async (element: any) => {
      try {
        let response = await this.dashboardService.GetDashboardBacteria(
          element.id
        );
        if (response.status === 200) {
          let measurements = await this.dashboardService.GetMeasurements(
            element.id
          );
          console.log("measurements");
          console.log(measurements);
          let bmiValue =
            measurements.data && measurements.data["bmi"]
              ? measurements.data["bmi"]
              : null;
          let whrValue =
            measurements.data && measurements.data["hip_waist_ratio"]
              ? measurements.data["hip_waist_ratio"]
              : null;

              console.log(bmiValue);
              console.log(whrValue);
          let nutrientValues = (
            await this.dietaryDashService.GetNutrientAdvice(element.id)
          ).data["nutrients"];
          const surveyService = new SurveyService();
          const surveyAnswers = await surveyService.GetUserServeyAnswers(
            element.id
          );
          this.newSurvey = surveyAnswers.data.some(
            (item) => item.question_id === 275
          );

          const genderAnswer = surveyAnswers.data.find(
            (item) => item.question_id === 279
          );
          if (genderAnswer && genderAnswer.answer_text === "Female") {
            this.isFemale = true;
          } else {
            this.isFemale = false;
          }
          // let nutrientValues = await (
          //   await this.dietaryDashService.GetNutrientAdvice(element.id)
          // ).data;
          let temp = {
            id: element.id,
            date: element.date,
            name: element.name,
            bmi: bmiValue,
            whr: whrValue,
            isFemale: this.isFemale,
            nutrients: nutrientValues,
            //@ts-ignore
            shannon: response.data.graphs.find(
              (x: any) => x.graph === "Shannon"
            ).value,
            //@ts-ignore
            otus: response.data.graphs.find((x: any) => x.graph === "OTUs")
              .value,
            //@ts-ignore
            pielou: response.data.graphs.find(
              (x: any) => x.graph === "Evenness"
            ).value,
            data: response.data,
            //@ts-ignore
            top35: response.data.top35,
            // nutrientValues: nutrientValues,
          };
          this.kitsData.push(temp);
        }
      } catch (error) {
        console.error(error);
      }
    });
    await Promise.all(kitPromises);
    this.kitsData.sort(function (a: any, b: any) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      a = new Date(a.date);
      b = new Date(b.date);
      return a - b;
    });

    this.isDataLoaded = true;
  }
}
