import { Injectable } from "@angular/core";
import axios from "axios";
import { environment } from "../../../environments/environment";

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

@Injectable()
export class DashboardService {
  constructor() {}

  async GetDashboardBacteria(testkitId: number) {
    return axios.get<string>(
      `/api/v1/calculations_human/dashboard_bacteria/${testkitId}`,
      authHeaders()
    );
  }

  async GetMeasurements(testkitId: number) {
    return axios.get<number>(
      `/api/v1/calculations_human/measurements?testkit_id=${testkitId}`,
      authHeaders()
    );
  }
  async GetHipWaist(testkitId: number) {
    return axios.get<any>(
      `/api/v1/calculations_human/hip_waist?testkit_id=${testkitId}`,
      authHeaders()
    );
  }

  async GetbacteriaLevels(testkitId: number) {
    return axios.get<number[]>(
      `/api/v1/calculations_human/bacteria_level/${testkitId}`,
      authHeaders()
    );
  }

  async GetNutrientLevels(testkitId: number) {
    return axios.get<number[]>(
      `/api/v1/calculations_human/target_values/${testkitId}`,
      authHeaders()
    );
  }

  async GetSurveyAnswers(testkitId: number, questionId: number) {
    return axios.get<number[]>(
      `/api/v1/calculations_human/dashboard_diet/${testkitId}/${questionId}`,
      authHeaders()
    );
  }
  async GetFullBacteriaList(testkitId: number) {
    return axios.get<string>(
      `/api/v1/result_uploads/output/${testkitId}`,
      authHeaders()
    );
  }

  async GetTakeHomeMessage(
    subjectId: number,
    testkitId: string,
    languageCode: string
  ) {
    return axios.get<string>(
      `/api/v1/take_home/?subject_id=${subjectId}&uid_testkit=${testkitId}&language_code=${languageCode}`,
      authHeaders()
    );
  }
  async DownloadTakeHomeMessage(
    subjectId: number,
    testkitId: string,
    languageCode: string,
    theme: string
  ) {
    return axios.get<string>(
      `/api/v1/take_home/download_food_diary?subject_id=${subjectId}&uid_testkit=${testkitId}&language_code=${languageCode}&theme=${theme}`,
      authHeaders()
    );
  }
}
