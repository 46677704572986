import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../api/login/authentication";
import { UtilityService } from "../utility.service";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-password-recovery",
  templateUrl: "./password-recovery.component.html",
  styleUrls: ["./password-recovery.component.css"],
})
export class PasswordRecoveryComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilityService,
    private translate: TranslateService,
    private app: AppComponent
  ) {}

  recoverForm: FormGroup;
  btnRecover = "Recover your password";
  lblEmail = "Email address";

  ngOnInit() {
    this.recoverForm = new FormGroup({
      email: new FormControl(null, [Validators.required]),
    });
  }

  async recoverPassword() {
    try {
      let language = localStorage.getItem("language");
      if (this.app.partnerVersion == "astrolab" && language != "en" && language != "es"){
        language = "en";
      }
        const response = await this.authenticationService.passwordRecovery(
          this.recoverForm.get("email").value.toLowerCase(),
          this.app.partnerVersion,
          language
        );
      this.utils.showSuccess("PW_RECOVERY_SUCCESS", "PW_RECOVERY_SUCCESS");
    } catch {
      this.utils.showError(
        "PASSWORD_RECOVERY_ERROR",
        "PASSWORD_RECOVERY_ERROR"
      );
    }
    console.log(localStorage.getItem("language"));
  }
}
