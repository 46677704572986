import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  IDashboardDietary,
  INutritionAdvice,
  IEyyo,
} from "../../../interfaces/advice";
import bacteriaSubCategories from "../../assets/json/bacteriaSubCategories.json";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { AppComponent } from "../app.component";
import { SubjectService } from "../api/test/subject";

@Component({
  selector: "app-gut-health",
  templateUrl: "./gut-health.component.html",
  styleUrls: ["./gut-health.component.css"],
})
export class GutHealthComponent implements OnInit {
  @ViewChild("infoIcon", { static: false }) infoIcon: ElementRef | null = null;
  bacteriaDescription: any;
  constructor(
    public dietaryDashService: DietaryDashService,
    private route: ActivatedRoute,
    public app: AppComponent,
    private translate: TranslateService,
    private subjectService: SubjectService
  ) {}

  resultText = "";
  dietaryAdviceText = "";
  introTableText = "";
  gutHealth;
  range;
  dashBacteria: IEyyo;
  dietaryAdvice: INutritionAdvice[];
  medical_tags_legend: number[];
  foodCategories: String[] = [];
  selectedFoodCategory: String;
  pageTitle: string;
  textString: string;
  selectedBacteria: string;
  bacteriaCategories = bacteriaSubCategories;
  lowRangeBacteria: number = 0;
  avgRangeBacteria: number = 0;
  hoveredTag: Number | null = null;
  hoveredTagLegend: Number | null = null;

  foodsAvailable: boolean;
  dietaryAdviceAvailable: boolean;
  testKitID: number; // ID of the test kit
  loading: boolean = true; // Loading state for data fetching

  hoveredFoodItem: any | null = null;
  dynamicMaxWidth: string | null = null;
  highRangeBacteria: number = 0;
  overallResult: string;
  flipGraphColorItems: string[] = [
    "POTENTIAL_COLON_PROBLEMS_NEW",
    "INFECTION_ALARM_NEW",
    "FAT_ALARM_NEW",
  ];
  gutChallengesGroup: string[] = [
    "FAT_ALARM_NEW",
    "INFECTION_ALARM_NEW",
    "POTENTIAL_COLON_PROBLEMS_NEW",
  ];
  encouragedFoods: object[] = [];
  encouragedFoodCategories: string[] = [];
  limitedFoods: object[] = [];
  limitedFoodsCategories: string[] = [];
  selectedLanguage: string = localStorage.getItem("language");
  selectedCategories: {
    encourage: { [key: string]: boolean };
    limit: { [key: string]: boolean };
  } = {
    encourage: {},
    limit: {},
  };

  ibsTagsIds: string[] = ["128", "152", "352", "540", "671"];
  glutenTagsIds: string[] = ["150", "151", "369", "370", "561", "562", "645"];
  lactoseTagsIds: string[] = ["153", "154", "371", "372", "563", "564", "639"];
  histamineTagsIds: string[] = ["633"];

  hasIbsCondition: boolean = false;
  hasGlutenCondition: boolean = false;
  hasLactoseCondition: boolean = false;
  hasHistamineCondition: boolean = false;

  showIbsTag: boolean = false;
  showGlutenTag: boolean = false;
  showLactoseTag: boolean = false;
  showHistamineTag: boolean = false;

  conditions: any;

  partnerImgSrc = "";
  medicalTagImages = {
    1: "assets/medical_tags/ibs.png",
    2: "assets/medical_tags/gluten.png",
    3: "assets/medical_tags/lactose.png",
    4: "assets/medical_tags/histamine.png",
  };

  medicalTagDescriptions = {
    2: "SUB_CATEGORIES.GUT_WALL_STRENGTH.GLUTEN_IMG_DESCRIPTION",
    4: "SUB_CATEGORIES.GUT_WALL_STRENGTH.HISTAMINE_IMG_DESCRIPTION",
    1: "SUB_CATEGORIES.GUT_WALL_STRENGTH.IBS_IMG_DESCRIPTION",
    3: "SUB_CATEGORIES.GUT_WALL_STRENGTH.LACTOSE_IMG_DESCRIPTION",
  };
  medicalTagDescriptionsLegend = {
    2: "SUPPLEMENT_ADVICE.RECOMMENDATIONS_PREBIOTICS_INFO_GLUTEN",
    4: "SUPPLEMENT_ADVICE.RECOMMENDATIONS_PREBIOTICS_INFO_HISTAMINE",
    1: "SUPPLEMENT_ADVICE.RECOMMENDATIONS_PREBIOTICS_INFO_IBS",
    3: "SUPPLEMENT_ADVICE.RECOMMENDATIONS_PREBIOTICS_INFO_LACTOSE",
  };

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.partnerImgSrc = "-" + this.app.partnerVersion;
    }

    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));

    this.testKitID = Number(this.route.params["value"].id);

    let tempTitle = this.route.params["value"].page.replaceAll("-", " ");
    this.pageTitle = tempTitle.charAt(0).toUpperCase() + tempTitle.slice(1);
    this.textString =
      // "SUB_CATEGORIES." + this.pageTitle.replaceAll(" ", "_").toUpperCase();
      "SUB_CATEGORIES." + this.pageTitle.split(" ").join("_").toUpperCase();

    this.selectedBacteria = this.pageTitle.split(" ").join("_").toUpperCase();

    const dietaryAdviceResponse = await (
      await this.dietaryDashService.GetDietaryAdvice(
        this.testKitID,
        this.pageTitle,
        this.app.selectedLanguage.split("-")[0]
      )
    ).data;
    this.dietaryAdvice = dietaryAdviceResponse["nutrition_advice"];
    this.foodsAvailable = dietaryAdviceResponse["foods_available"];
    this.dietaryAdviceAvailable = dietaryAdviceResponse["activated"];

    console.log(dietaryAdviceResponse);

    if (Array.isArray(dietaryAdviceResponse["medical_tags"])) {
      const sortOrder = [2, 4, 1, 3]; // Explicit sort order
      this.medical_tags_legend = dietaryAdviceResponse["medical_tags"].sort(
        (a, b) => {
          return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        }
      );
    } else {
      console.error(
        "medical_tags is not an array:",
        dietaryAdviceResponse["medical_tags"]
      );
      this.medical_tags_legend = [];
    }
    this.getMedicalConditions();

    if (this.gutChallengesGroup.includes(this.selectedBacteria)) {
      this.splitGutChallengeFoods();
    }

    this.getOverallResult();
    this.splitFoodItemCategories();
    this.loading = false;
    // this.setText();
  }
  toggleCategory(category: string, type: "encourage" | "limit") {
    this.selectedCategories[type][category] =
      !this.selectedCategories[type][category];
  }

  isCategoryOpen(category: string, type: "encourage" | "limit"): boolean {
    return !!this.selectedCategories[type][category];
  }
  async getMedicalConditions() {
    this.conditions = await this.dietaryDashService.GetMedicalCondition(
      Number(this.route.params["value"].id)
    );
    console.log(this.conditions.data);
    this.conditions.data.forEach((element) => {
      if (this.ibsTagsIds.includes(element.answerID.toString())) {
        this.hasIbsCondition = true;
      }
      if (this.glutenTagsIds.includes(element.answerID.toString())) {
        this.hasGlutenCondition = true;
      }
      if (this.lactoseTagsIds.includes(element.answerID.toString())) {
        this.hasLactoseCondition = true;
      }
      if (this.histamineTagsIds.includes(element.answerID.toString())) {
        this.hasHistamineCondition = true;
      }
    });
  }

  getMedicalTagImage(tagId: number): string | null {
    const baseImage = this.medicalTagImages[tagId];
    if (!baseImage) {
      return null; // Return null if no image is found for the tagId
    }
    return baseImage.replace(".png", `${this.partnerImgSrc}.png`);
  }
  isHovered(tag: number, foodItem: any): boolean {
    console.log("here");
    return this.hoveredTag === tag && this.hoveredFoodItem === foodItem;
  }
  getMedicalTagDescription(tagId: number): string {
    const descriptionKey = this.medicalTagDescriptions[tagId];
    return this.translate.instant(descriptionKey); // Translate the description
  }
  getMedicalTagDescriptionLegend(tagId: number): string {
    const descriptionKey = this.medicalTagDescriptionsLegend[tagId];
    return this.translate.instant(descriptionKey); // Translate the description
  }
  getSortedMedicalTags(medicalTags: number[]): number[] {
    return medicalTags.sort((a, b) => {
      const nameA = this.getMedicalTagDescription(a).toLowerCase();
      const nameB = this.getMedicalTagDescription(b).toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }

  selectFoodCategory(category) {
    if (this.selectedFoodCategory == category) {
      this.selectedFoodCategory = "";
      return;
    }
    this.selectedFoodCategory = category;
  }

  splitFoodItemCategories() {
    this.dietaryAdvice.forEach((elem) => {
      if (!this.foodCategories.includes(elem.food_item.food_group_title)) {
        this.foodCategories.push(elem.food_item.food_group_title);
      }

      if (
        elem.food_item["medical_tags_new"] != null &&
        elem.food_item["medical_tags_new"] != ""
      ) {
        const tags = elem.food_item["medical_tags_new"];
        console.log(tags);
        const sortedTags = this.getSortedMedicalTags(tags);
        sortedTags.forEach((tagId) => {
          switch (tagId) {
            case 1:
              this.showIbsTag = true;
              break;
            case 2:
              this.showGlutenTag = true;
              break;
            case 3:
              this.showLactoseTag = true;
              break;
            case 4:
              this.showHistamineTag = true;
              break;
          }
        });
      }
    });
    this.foodCategories.sort();
  }

  splitGutChallengeFoods() {
    this.dietaryAdvice.forEach((elem) => {
      if (elem.is_positive) {
        if (!this.encouragedFoods.includes(elem)) {
          this.encouragedFoods.push(elem);
        }
        if (
          !this.encouragedFoodCategories.includes(
            elem.food_item.food_group_title
          )
        ) {
          this.encouragedFoodCategories.push(elem.food_item.food_group_title);
        }
      } else {
        if (!this.limitedFoods.includes(elem)) {
          this.limitedFoods.push(elem);
        }
        if (
          !this.limitedFoodsCategories.includes(elem.food_item.food_group_title)
        ) {
          this.limitedFoodsCategories.push(elem.food_item.food_group_title);
        }
      }

      if (
        elem.food_item["medical_tags_new"] &&
        elem.food_item["medical_tags_new"].length > 0
      ) {
        const tags = elem.food_item["medical_tags_new"];
        const sortedTags = this.getSortedMedicalTags(tags);
        sortedTags.forEach((tagId) => {
          switch (tagId) {
            case 1:
              this.showIbsTag = true;
              break;
            case 2:
              this.showGlutenTag = true;
              break;
            case 3:
              this.showLactoseTag = true;
              break;
            case 4:
              this.showHistamineTag = true;
              break;
          }
        });
      }
    });
    this.encouragedFoodCategories.sort();
    this.limitedFoodsCategories.sort();
  }

  getBacteriaLevel(bacteria) {
    if (typeof bacteria === "string") {
      const temp = this.dashBacteria.top35.find((n) => n.bacteria === bacteria);
      if (temp && typeof temp.value === "number" && Array.isArray(temp.range)) {
        const [left, right] = temp.range.map(Number); // Ensure range values are numbers
        const value = temp.value;

        // Calculate diff and max_val
        const diff = ((right - left) / 70) * 15;
        const max_val = right + diff;

        // Define the raw ranges
        const ranges_left_raw = [Math.max(left - diff, 0), left];
        const ranges_middle_raw = [left, right];
        const ranges_right_raw = [right, max_val];

        // Define the corresponding segments
        const segments = [
          [0, 15], // Left segment
          [15, 85], // Middle segment
          [85, 100], // Right segment
        ];

        let val = 0;

        // Apply the value calculation logic
        if (value > ranges_right_raw[0]) {
          // Right segment calculation
          val =
            segments[2][0] +
            ((segments[2][1] - segments[2][0]) *
              (value - ranges_right_raw[0])) /
              (ranges_right_raw[1] - ranges_right_raw[0]);
        } else if (value > ranges_middle_raw[0]) {
          // Middle segment calculation
          val =
            segments[1][0] +
            ((segments[1][1] - segments[1][0]) *
              (value - ranges_middle_raw[0])) /
              (ranges_middle_raw[1] - ranges_middle_raw[0]);
        } else {
          // Left segment calculation
          if (ranges_left_raw[1] - ranges_left_raw[0] === 0) {
            val = segments[0][1]; // Avoid division by zero
          } else {
            val =
              segments[0][0] +
              ((segments[0][1] - segments[0][0]) *
                (value - ranges_left_raw[0])) /
                (ranges_left_raw[1] - ranges_left_raw[0]);
          }
        }

        // Clamp the value to ensure it stays within the range [0, 100]
        val = Math.max(0, Math.min(100, val));

        return `calc(${val}% - 15px)`;
      }
    }
    return `calc(0% - 15px)`; // Fallback for invalid input
  }

  resultSampleText() {
    if (this.gutHealth < this.range[0]) {
      this.createResultString("GUT_HEALTH.BE_AWARE_RESULT");
      return;
    }
    if (this.gutHealth > this.range[1]) {
      this.createResultString("GUT_HEALTH.GREAT_RESULT");
      return;
    }
    this.createResultString("GUT_HEALTH.NORMAL_RESULT");
  }

  getOverallResult() {
    this.bacteriaCategories[this.selectedBacteria].forEach((element) => {
      let temp = this.dashBacteria.top35.filter(
        (n) => n.bacteria == element
      )[0];
      if (temp.status == "low") {
        this.lowRangeBacteria++;
      }
      if (temp.status == "avg") {
        this.avgRangeBacteria++;
      }
      if (temp.status == "high") {
        this.highRangeBacteria++;
      }
    });

    // Handle the case where only low bacteria are present
    if (
      this.lowRangeBacteria > 0 &&
      this.avgRangeBacteria == 0 &&
      this.highRangeBacteria == 0
    ) {
      this.overallResult = "LOW_TEXT";
      return;
    }

    // Handle the case where only high bacteria are present
    if (
      this.highRangeBacteria > 0 &&
      this.avgRangeBacteria == 0 &&
      this.lowRangeBacteria == 0
    ) {
      this.overallResult = "HIGH_TEXT";
      return;
    }

    // Handle the case where only avg bacteria are present
    if (
      this.avgRangeBacteria > 0 &&
      this.lowRangeBacteria == 0 &&
      this.highRangeBacteria == 0
    ) {
      this.overallResult = "AVG_TEXT";
      return;
    }

    // Case where high and average bacteria are equal, but different from low
    if (
      this.highRangeBacteria == this.avgRangeBacteria &&
      this.highRangeBacteria != this.lowRangeBacteria
    ) {
      this.overallResult = "HIGH_NORMAL_TEXT";
      if (
        !this.flipGraphColorItems.includes(this.selectedBacteria) &&
        this.selectedBacteria != "FIBRE_DEGRADATION"
      ) {
        return;
      }
    }

    // Case where high-range bacteria dominate
    if (
      this.highRangeBacteria > this.avgRangeBacteria &&
      this.highRangeBacteria > this.lowRangeBacteria
    ) {
      this.overallResult = "HIGH_TEXT";
    } else if (
      this.lowRangeBacteria >= this.highRangeBacteria &&
      this.lowRangeBacteria >= this.avgRangeBacteria
    ) {
      this.overallResult = "LOW_TEXT";
    } else {
      this.overallResult = "AVG_TEXT";
    }

    // Gut challenges
    if (this.flipGraphColorItems.includes(this.selectedBacteria)) {
      if (this.lowRangeBacteria == this.avgRangeBacteria) {
        if (this.lowRangeBacteria > 0 && this.avgRangeBacteria > 0) {
          this.overallResult = "HIGH_NORMAL_TEXT";
          return;
        }
      }
      if (
        this.highRangeBacteria >= this.avgRangeBacteria &&
        this.highRangeBacteria >= this.lowRangeBacteria
      ) {
        this.overallResult = "HIGH_TEXT";
        return;
      }
    }

    // Fibre degradation case
    if (this.selectedBacteria == "FIBRE_DEGRADATION") {
      if (this.highRangeBacteria == this.lowRangeBacteria) {
        if (this.highRangeBacteria > 0 && this.lowRangeBacteria > 0) {
          this.overallResult = "HIGH_NORMAL_TEXT";
          return;
        }
      }
      if (this.avgRangeBacteria == this.lowRangeBacteria) {
        if (this.avgRangeBacteria > 0 && this.lowRangeBacteria > 0) {
          this.overallResult = "LOW_TEXT";
          return;
        }
      }
      if (this.avgRangeBacteria == this.highRangeBacteria) {
        if (this.highRangeBacteria > 0 && this.avgRangeBacteria > 0) {
          this.overallResult = "HIGH_TEXT";
          return;
        }
      }
    }
  }

  createResultString(tag: string) {
    this.translate.get(tag).subscribe((text: string) => {
      this.resultText = text;
    });
  }

  setText() {
    this.resultSampleText();
    this.translate
      .get("GUT_HEALTH.DIETARY_ADVICE_TEXT")
      .subscribe((text: string) => {
        this.dietaryAdviceText = text;
      });
    this.translate
      .get("GUT_HEALTH.INTRO_TABLE_TEXT")
      .subscribe((text: string) => {
        this.introTableText = text;
      });
  }

  getFoodCategoryText(category: string) {
    console.log(category);
    if (
      [
        "Plant-based alternatives",
        "Plantaardige alternatieven",
        "Alternative a base vegetale",
        "Pflanzliche Alternativen",
        "Rastlinné alternatívy",
        "Alternatives végétales",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.PLANT_BASED_ALTERNATIVES";
    } else if (
      [
        "Vegetables",
        "Groenten",
        "Verdure",
        "Gemüse",
        "Zelenina",
        "Légumes",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.VEGETABLES";
    } else if (
      [
        "Beverages",
        "Dranken",
        "Bevande",
        "Getränke",
        "Nápoje",
        "Breuvages",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.BEVERAGES";
    } else if (
      [
        "Cereals",
        "Granen",
        "Cereali",
        "Getreide",
        "Obilniny",
        "Céréales",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.CEREALS";
    } else if (
      [
        "Condiments and seasonings",
        "Vetten en smaakmakers",
        "Condimenti e aromi",
        "Gewürze und Gewürze",
        "Koreniny, dochucovadlá a oleje",
        "Condiments et assaisonnements",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.CONDIMENTS_AND_SEASONINGS";
    } else if (
      [
        "Dairy",
        "Zuivel",
        "Latticini",
        "Molkerei",
        "Mliečne výrobky",
        "Laitier",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.DAIRY";
    } else if (
      ["Fish", "Vis", "Pesce", "Fisch", "Ryba", "Poisson"].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.FISH";
    } else if (
      ["Fruits", "Fruit", "Frutta", "Früchte", "Ovocie", "Des fruits"].includes(
        category
      )
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.FRUITS";
    } else if (
      [
        "Legumes",
        "Peulvruchten",
        "Legumi",
        "Hülsenfrüchte",
        "Strukoviny",
        "Les légumineuses",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.LEGUMES";
    } else if (
      [
        "Nuts and seeds",
        "Noten en zaden",
        "Frutta secca e semi",
        "Nüsse und Samen",
        "Orechy a semená",
        "Noix et graines",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.NUTS_AND_SEEDS";
    } else if (
      [
        "Sweets, confectionery and pastries",
        "Snoep, zoetwaren en gebak",
        "Dolci, confetteria e pasticceria",
        "Süßigkeiten, Süßwaren und Gebäck",
        "Sladkosti, cukrovinky a pečivo",
        "Bonbons, confiseries et pâtisseries",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.SWEETS_CONFECTIONERY_AND_PASTRIES";
    } else if (
      [
        "White meat",
        "Wit vlees",
        "Carne bianca",
        "Weisses Fleisch",
        "Biele mäso",
        "viande blanche",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.WHITE_MEAT";
    } else {
      return "FOOD_GROUP_DESCRIPTIONS.EMPTY";
    }
  }
  showTagInfo(tag: number, foodItem: any): void {
    console.log(tag);
    if (!tag || !foodItem) return; // Exit if no tag or foodItem is provided
    this.hoveredTag = tag;
    this.hoveredFoodItem = foodItem;

    if (this.infoIcon) {
      const iconPosition =
        this.infoIcon.nativeElement.getBoundingClientRect().left;
      this.dynamicMaxWidth =
        this.calculateMaxWidthFromIconPosition(iconPosition).toString();
    }
  }
  showTagInfoLegend(tag: number): void {
    console.log(tag);
    if (!tag) return; // Exit if no tag or foodItem is provided
    this.hoveredTagLegend = tag;

    if (this.infoIcon) {
      const iconPosition =
        this.infoIcon.nativeElement.getBoundingClientRect().left;
      this.dynamicMaxWidth =
        this.calculateMaxWidthFromIconPosition(iconPosition).toString();
    }
  }
  hideTagInfoLegend(): void {
    this.hoveredTagLegend = null;
  }
  hideTagInfo(): void {
    this.hoveredTag = null;
    this.hoveredFoodItem = null;
  }

  calculateMaxWidthFromIconPosition(iconPosition: number): string {
    const viewportWidth = window.innerWidth;
    const remainingWidthInPx = viewportWidth - iconPosition;

    // Convert the remaining width to vw units
    const remainingWidthInVw = (remainingWidthInPx / viewportWidth) * 100;

    // Return the result as a string with 'vw' appended
    return `${Math.max(remainingWidthInVw - 10, 5)}vw`; // Set a minimum of 5vw for better UI scaling
  }
  toggleBacteriaDescription(bacteria) {
    bacteria
      ? (this.bacteriaDescription = bacteria)
      : (this.bacteriaDescription = "");
  }
}
