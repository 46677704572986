import { Injectable } from "@angular/core";
import axios from "axios";
import {
  IDashboardDietary,
  INutrientAdvice,
  IProbioticPrebioticAdvice,
  
} from "../../../../interfaces/advice";
import { environment } from "../../../environments/environment";


axios.defaults.baseURL = environment.url.api;

function authHeaders() {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

@Injectable()
export class DietaryDashService {
  constructor() {}

  async GetDietaryAdvice(testkitId: number, page: string, languageCode: string) {
    return axios.get<IDashboardDietary[]>(
      `/api/v1/calculations_human/dashboard_dietary/${testkitId}/{category}?category_page=${page}&language_code=${languageCode}`,
      authHeaders()
    );
  }
  async GetNoory(subjectId: number) {
    return axios.get(
      `/api/v1/noory/?subject_id=${subjectId}`,
      authHeaders()
    );
  }

  async GetDietaryAdvicePerBacteria(testkitId: number, page: string) {
    return axios.get<IDashboardDietary[]>(
      `/api/v1/calculations_human/dashboard_dietary_single/${testkitId}/{category}?category_page=${page}`,
      authHeaders()
    );
  }

  async GetNutrientAdvice(testkitId: number) {
    return axios.get<INutrientAdvice[]>(
      `/api/v1/calculations_human/personalized_dietary_advice/${testkitId}`,
      authHeaders()
    );
  }

  async GetMedicalCondition(testkitId: number) {
    return axios.get<INutrientAdvice[]>(
      `/api/v1/user_survey_answermedical_conditions/${testkitId}`,
      authHeaders()
    );
  }

 
  async GetSupplementAdvice(testkitId: number, languageCode: string): Promise<IProbioticPrebioticAdvice | undefined> {
    try {
      const response = await axios.get<IProbioticPrebioticAdvice>(
        `/api/v1/supp_advice/?kit_id=${testkitId}&language_code=${languageCode}`,
        authHeaders()
      );
      
      console.log("Response: ", response.data); // Log the response for testing
      return response.data; // Return the single object
    } catch (error) {
      console.error("Error fetching supplement advice: ", error.message);
      return undefined; // Return undefined in case of error
    }
  }

  async GetSupplementAdvicePDF(testkitId: number, languageCode: string): Promise<{ pdf_link: { file_url: string } }> {
    try {
      const response = await axios.get<{ pdf_link: { file_url: string } }>(
        `/api/v1/supp_advice/download_pdf?kit_id=${testkitId}&language_code=${languageCode}`,
        authHeaders()
      );
      
      console.log("Response: ", response.data); // Log the response for testing
      return response.data; // Return the PDF link object
    } catch (error) {
      console.error("Error fetching PDF link: ", error.message);
      throw new Error("Failed to fetch PDF link");
    }
  
  }
  
  
}
