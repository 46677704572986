import { Theme } from './symbols';

export const patrisHealthTheme: Theme = {
  name: 'patris_health',
  properties: {
    '--color-profile-tab': '#33ab9f',  // Main theme color
    '--navbar-img': 'url("assets/patris-health-logo.png")',
    '--footer-img': 'url("assets/patris-health-logo.png")',
    '--navbar-color': '#33ab9f',  // Darker shade for nav elements
    '--navbar-light-color': '#ffffff',  // Light text color
    '--conditions-color': '#2ea098',  // Slightly lighter than the main color
    '--navigation-color': '#2ea098',  // Consistent with conditions color
    '--contact-color': '#2ea098',
    '--copyrights-color': '#268378',  // Darker accent for text
    '--btn-dark-color': 'linear-gradient(135deg, #2b968d 0%, #33ab9f 100%)', // Gradient with main color accents
    '--btn-light-color': '#33ab9f',  // Semi-transparent light green
    '--btn-light-color-active': '#33ab9f',  // Full opacity of light green
    '--table-header-color': '#33ab9f',  // Matches primary color
    '--kit-code-btn-color': '#33ab9f',
    '--btn-fill-in-color': '#33ab9f',  // Light green with opacity
    '--btn-fill-in-color-active': '#33ab9f',
    '--food-diary-color': '#33ab9f',  // Primary color for high importance
    '--food-diary-color-light': '#f5f7fa',  // Very light blue-green for backgrounds
    '--survey-color': '#33ab9f',
    '--survey-color-complete': '#2b9085',  // Dark green for completed surveys
    '--dashboard-container': '#f5f7fa',  // Lightened primary color for containers
    '--dashboard-container-hover': '#33ab9f54',  // Slightly stronger hover effect
    '--sub-dropdown-color': '#33ab9f',
    '--dropdown-box': '#33ab9f26',  // Very light blue-green for dropdown boxes
    '--compound-container-color': '#B6D6D3',
    '--normal-color': '#33ab9f',  // Soft green for “normal” indications
    '--okay-color': '#81c784',  // Lighter green for “okay” indications 
    '--less-great-color': '#85D88A',
    '--great-color': '#66bb6a',  // Bright green for “great”
    '--bad-color': '#f44336',  // Red for negative/bad status
    '--aware-color': '#b2dfdb',  // Muted light green for awareness notes
    '--warning-color': '#f44336',  // Red for warnings
    '--warning-color-fibre': '#f44336',  // Slightly brighter red for emphasis
    '--take-home-bubble-color': '#33ab9f',  // Matches “normal” color for callouts
    '--rectangle-color': '#33ab9f26',  // Light gray-blue for rectangles
    '--description-color': '#f5f7fa',  // Very light overlay of the navbar color
    '--protein-color': '#33ab9f',  // Matches rectangle color for consistency
    '--bmi-1-color': '#ffc400',  // Very light primary color for BMI level 1
    '--bmi-2-color': '#66bb6a',  // Gradual increase in opacity for BMI 2
    '--bmi-3-color': '#ffc400',  // Higher opacity for BMI level 3
    '--bmi-4-color': '#f44336',  // Strong opacity for BMI level 4
    '--tg-1-color': '#33ab9f26',
    '--tg-2-color': '#33ab9f40',
    '--tg-3-color': '#33ab9f7d',
    '--recomendations-color': '#33ab9f',  // Primary color for recommendations
    '--img-placeholder-color': '#33ab9f',  // Consistent light background color
    '--fibre-color': '#b2dfdb8f',  // Light semi-transparent color for fiber
    '--green-color': '#66bb6a', 
    '--yellow-color': '#f44336',
    '--lessnormal-color': '#B6D6D3',
    '--morenormal-color': '#B6D6D3',
    '--text-color': '#ffffff',
    '--footer-justify-content': 'space-between',
    '--button-light-1-color': '#33ab9f26', 
  }
};