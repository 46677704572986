import { Component, NgModule, OnInit, ViewChild } from "@angular/core";
import { SubjectService } from "../api/test/subject";
import { TranslateService } from "@ngx-translate/core";
import { BacteriaLevelsComponent } from "../bacteria-levels/bacteria-levels.component";
import { ActivatedRoute } from "@angular/router";
import { TestKitIdService } from "../testkit-id-get/testkitid.service";
import { DashboardService } from "../api/calculations/dashboard";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  private bacteriaLevelsComponent: BacteriaLevelsComponent;
  loading: boolean = true;

  pdfLinks = {
    dashboard: null,
    supplementAdvice: null,
    nutritionAdvice: null,
    dietaryAdvice: null,
  };
  testKitID: number;

  constructor(
    private translate: TranslateService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private testKitIdService: TestKitIdService,
    public app: AppComponent,
    private subjectService: SubjectService
  ) {
    this.bacteriaLevelsComponent = new BacteriaLevelsComponent(
      this.translate,
      this.dashboardService,
      this.route,
      this.testKitIdService,
      this.app,
      this.subjectService
    );
  }

  async ngOnInit() {
    this.testKitID = this.testKitIdService.getTestKitID();
    await this.bacteriaLevelsComponent.ngOnInit();
    this.loadPDFs();
  }

  async loadPDFs() {
    try {
      const response = await this.subjectService.GetPdfs(
        this.testKitID,
        localStorage.getItem("language")
      );
      console.log(response.data);
      if (response.data) {
        this.pdfLinks.dashboard = response.data["dashboard"];
        this.pdfLinks.supplementAdvice = response.data["supplement_advice"];
        this.pdfLinks.nutritionAdvice = response.data["nutrition_advice"];
        this.pdfLinks.dietaryAdvice = response.data["dietary_advice"];
        console.log(this.pdfLinks.dietaryAdvice);
      }
    } catch (error) {
      console.error("Error loading PDF links:", error);
    } finally {
      this.loading = false;
    }
  }

  openPDF(url: string) {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("PDF URL is null");
    }
  }
  downloadBacteriaPDF() {
    if (this.bacteriaLevelsComponent) {
      this.bacteriaLevelsComponent.downloadBacList();
    } else {
      console.error("BacteriaLevelsComponent is not initialized");
    }
  }

  downloadBacteriaCSV() {
    if (this.bacteriaLevelsComponent) {
      this.bacteriaLevelsComponent.downloadBacCsv();
    } else {
      console.error("BacteriaLevelsComponent is not available");
    }
  }
}
