import { Component, OnInit, Input } from "@angular/core";
import { INutrientAdvice } from "interfaces/advice";
import { Chart } from "chart.js";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-comparison-target-nutrients",
  templateUrl: "./comparison-target-nutrients.component.html",
  styleUrls: ["./comparison-target-nutrients.component.css"],
})
export class ComparisonTargetNutrientsComponent implements OnInit {
  @Input() data: any = {};
  @Input() app: any;
  fibreObject: any;
  fiberValue: any;

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: [
      "iprobio_blue",
      "iprobio_blue_light",
      "iprobio_green",
      "iprobio_red",
      "iprobio_blue_dark",
    ],
    patris_health: [
      "patris_health_blue",
      "patris_health_green",
      "patris_health_purple",
      "patris_health_yellow",
      "patris_health_light_purple"
    ],
  };
  legendColors: any = this.legendColorsVariants["default"];
  carbohydratesObject: any;
  carbohydratesValueRanges: any;
  fatObject: any;
  satFatObject: any;
  proteinObject: any;
  saltObject: any;
  proteinValueRanges: any;
  fatValueRanges: any;
  saltValueRanges: any;
  saturatedFatValueRanges: any;

  constructor(private translate: TranslateService) {}

  doughnutChart: any;
  fiberValueRanges: any = [];
  carbohydratesValue = 5;
  fatValue = 4;
  saturatedFatValue = 3;
  sectionToDisplay: number = 0; // Default value or based on your logic

  saturatedFatTitle = "";
  fatTitle = "";
  carbsTitle = "";

  fiberChartValues: any;

  // fiber intake values
  lowerFiber = 10;
  mediumFiber = 10;
  higherFiber = 10;
  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      bad: "#f44336",
      aware: "#b2dfdb",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];
  fiberValues: any = [];
  saltValues: any = [];
  proteinValues: any = [];

  lowerSalt = 0;
  mediumSalt = 6;
  higherSalt = 2;

  ngOnInit() {
    console.log(this.data[0]["nutrients"]);
    let nutrients = this.data[0]["nutrients"];
    this.fibreObject = nutrients.find((x) => x.type === "fibers");
    this.carbohydratesObject = nutrients.find(
      (x) => x.type === "carbohydrates"
    );
    this.fatObject = nutrients.find((x) => x.type === "fat");
    this.satFatObject = nutrients.find((x) => x.type === "saturated_fat");
    this.proteinObject = nutrients.find((x) => x.type === "protein");
    this.saltObject = nutrients.find((x) => x.type === "salt");

    this.saturatedFatValueRanges =
      (this.satFatObject && this.satFatObject.range) || [];
    this.fatValueRanges = (this.fatObject && this.fatObject.range) || [];
    this.saltValueRanges = (this.saltObject && this.saltObject.range) || [];
    this.proteinValueRanges =
      (this.proteinObject && this.proteinObject.range) || [];
    this.carbohydratesValueRanges = this.carbohydratesObject.range.map(
      (range) => range
    );
    this.fiberValueRanges = (this.fibreObject && this.fibreObject.range) || [];
    this.calculateDietaryFiberChart(this.fibreObject);
    this.calculateCarbohydratesChart(this.carbohydratesObject);
    this.calculateProteinChart(this.proteinObject);
    this.calculateSaltChart(this.saltObject);
    this.calculateFatChart(this.fatObject);
    this.calculateSaturatedFatChart(this.satFatObject);
  }
  calculateFatChart(fatObject: INutrientAdvice) {
    if (!fatObject || !fatObject.range || fatObject.range.length === 0) {
      console.warn("Invalid fatObject data, using default values.");
      this.fatValueRanges = ["50%", "50%"]; // Default fallback
      return;
    }

    const totalRange = fatObject.range[fatObject.range.length - 1][1];
    this.fatValueRanges = fatObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );

    console.log("Fat Chart Values:", this.fatValueRanges);
  }

  calculateSaturatedFatChart(satFatObject: INutrientAdvice) {
    if (
      !satFatObject ||
      !satFatObject.range ||
      satFatObject.range.length === 0
    ) {
      console.warn("Invalid satFatObject data, using default values.");
      this.saturatedFatValueRanges = ["50%", "50%"]; // Default fallback for two ranges
      return;
    }

    const totalRange = satFatObject.range[satFatObject.range.length - 1][1];

    if (satFatObject.range.length === 2) {
      // If there are only two ranges, calculate percentages accordingly
      this.saturatedFatValueRanges = satFatObject.range.map(
        (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
      );
    } else {
      // For cases with more than two ranges, default logic
      this.saturatedFatValueRanges = satFatObject.range.map(
        (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
      );
    }
  }

  calculateProteinChart(proteinObject: INutrientAdvice) {
    if (
      !proteinObject ||
      !proteinObject.range ||
      proteinObject.range.length === 0
    ) {
      console.warn("Invalid proteinObject data, using default values.");
      this.proteinValueRanges = ["33%", "33%", "34%"]; // Default fallback
      return;
    }

    const totalRange = proteinObject.range[proteinObject.range.length - 1][1];
    this.proteinValueRanges = proteinObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );
  }
  calculateSaltChart(saltObject: INutrientAdvice) {
    if (!saltObject || !saltObject.range || saltObject.range.length === 0) {
      console.warn("Invalid saltObject data, using default values.");
      this.saltValueRanges = ["50%", "50%"]; // Default fallback
      return;
    }

    const totalRange = saltObject.range[saltObject.range.length - 1][1];
    this.saltValueRanges = saltObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );
  }
  getProteinLevel(item: any) {
    const proteinObject = item["nutrients"].find((x) => x.type === "protein");

    if (!proteinObject || !proteinObject.range) {
      console.warn("Invalid protein data");
      return "calc(0%)";
    }

    const totalRange = proteinObject.range[proteinObject.range.length - 1][1];
    const positionPercentage = (proteinObject.value / totalRange) * 100;

    console.log("Protein Needle Position:", positionPercentage);
    return `calc(${positionPercentage}% )`;
  }

  private validateSaltValue(value: number, max: number): number {
    if (value < 0) return 0;
    if (value > max) return max;
    return value;
  }
  getSaltLevel(item: any) {
    const saltValue = item["nutrients"].find((x) => x.type === "salt");
    if (!saltValue) {
      console.warn("Invalid salt data");
      return "calc(0% )";
    }

    const max = this.mediumSalt + this.higherSalt; // Calculate maximum range
    const validatedValue = this.validateSaltValue(saltValue.value, max);
    const cal = (validatedValue / max) * 100;

    console.log(`Validated Salt Value: ${validatedValue}`);
    return `calc(${cal}% )`;
  }
  getFatLevel(item: any) {
    const fatObject = item["nutrients"].find((x) => x.type === "fat");

    if (!fatObject || !fatObject.range) {
      console.warn("Invalid fat data");
      return "calc(0% )";
    }

    const totalRange = fatObject.range[fatObject.range.length - 1][1];
    const positionPercentage = (fatObject.value / totalRange) * 100;

    return `calc(${positionPercentage}% )`;
  }

  getSaturatedFatLevel(item: any) {
    const satFatObject = item["nutrients"].find(
      (x) => x.type === "saturated_fat"
    );

    if (!satFatObject || !satFatObject.range) {
      console.warn("Invalid saturated fat data");
      return "calc(0% )";
    }

    const totalRange = satFatObject.range[satFatObject.range.length - 1][1];
    const positionPercentage = (satFatObject.value / totalRange) * 100;

    return `calc(${positionPercentage}% )`;
  }
  getCarbohydratesLevel(item: any) {
    const carbohydratesObject = item["nutrients"].find(
      (x) => x.type === "carbohydrates"
    );

    if (!carbohydratesObject || !carbohydratesObject.range) {
      console.warn("Invalid carbohydrates data");
      return "calc(0%)";
    }

    const totalRange =
      carbohydratesObject.range[carbohydratesObject.range.length - 1][1];
    const positionPercentage = (carbohydratesObject.value / totalRange) * 100;

    return `calc(${positionPercentage}% )`;
  }

  getFiberLevel(item: any): string {
    const fiberObject = item["nutrients"].find((x) => x.type === "fibers");

    if (!fiberObject || !fiberObject.range) {
      console.warn("Invalid fiber data");
      return "0%"; // Default to the start of the chart
    }

    const totalRange = fiberObject.range[fiberObject.range.length - 1][1];
    const positionPercentage = (fiberObject.value / totalRange) * 100;

    // Ensure the value stays within bounds
    const adjustedPositionPercentage = Math.min(
      Math.max(positionPercentage, 0),
      100
    );

    return `${adjustedPositionPercentage}%`;
  }

  calculateCarbohydratesChart(carbohydratesObject: INutrientAdvice) {
    if (
      !carbohydratesObject ||
      !carbohydratesObject.range ||
      carbohydratesObject.range.length === 0
    ) {
      console.warn("Invalid carbohydratesObject data, using default values.");
      this.carbohydratesValueRanges = ["33%", "33%", "34%"]; // Default fallback
      return;
    }

    const totalRange =
      carbohydratesObject.range[carbohydratesObject.range.length - 1][1];
    this.carbohydratesValueRanges = carbohydratesObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );

    console.log("Carbohydrates Chart Values:", this.carbohydratesValueRanges);
  }
  calculateDietaryFiberChart(fiberObject: INutrientAdvice) {
    if (!fiberObject || !fiberObject.range || fiberObject.range.length === 0) {
      console.warn("Invalid fiberObject data, using default values.");
      this.fiberChartValues = ["33%", "33%", "34%"]; // Default fallback
      return;
    }

    const totalRange = fiberObject.range[fiberObject.range.length - 1][1];
    this.fiberChartValues = fiberObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );
  }
  sharesFiberValue(item: any, index: number): boolean {
    let shouldFlip = false;

    this.data.forEach((element, idx) => {
      if (element !== item) {
        const nut1 = element["nutrients"].find((x) => x.type === "fibers");
        const nut2 = item["nutrients"].find((x) => x.type === "fibers");

        if (nut1 && nut2) {
          const difference = Math.abs(nut1.value - nut2.value);

          // If the values are close and the current index is greater, mark as flipped
          if (difference < 0.02 && idx < index) {
            shouldFlip = true;
          }
        }
      }
    });

    return true;
  }
  sharesCarbsValue(item: any, index: number): boolean {
    let shouldFlip = false;

    this.data.forEach((element, idx) => {
      if (element !== item) {
        const nut1 = element["nutrients"].find(
          (x) => x.type === "carbohydrates"
        );
        const nut2 = item["nutrients"].find((x) => x.type === "carbohydrates");

        if (nut1 && nut2) {
          const difference = Math.abs(nut1.value - nut2.value);

          // If the values are close and the current index is greater, mark as flipped
          if (difference < 0.02 && idx < index) {
            shouldFlip = true;
          }
        }
      }
    });

    return true;
  }
  sharesProteinValue(item: any, index: number): boolean {
    let shouldFlip = false;

    this.data.forEach((element, idx) => {
      if (element !== item) {
        const nut1 = element["nutrients"].find((x) => x.type === "protein");
        const nut2 = item["nutrients"].find((x) => x.type === "protein");

        if (nut1 && nut2) {
          const difference = Math.abs(nut1.value - nut2.value);

          // If the values are close and the current index is greater, mark as flipped
          if (difference < 0.02 && idx < index) {
            shouldFlip = true;
          }
        }
      }
    });

    return true;
  }
  sharesFatValue(item: any, index: number): boolean {
    let shouldFlip = false;

    this.data.forEach((element, idx) => {
      if (element !== item) {
        const nut1 = element["nutrients"].find(
          (x) => x.type === "carbohydrates"
        );
        const nut2 = item["nutrients"].find((x) => x.type === "carbohydrates");

        if (nut1 && nut2) {
          const difference = Math.abs(nut1.value - nut2.value);

          // If the values are close and the current index is greater, mark as flipped
          if (difference < 0.02 && idx < index) {
            shouldFlip = true;
          }
        }
      }
    });

    return true;
  }
  sharesSaltValue(item: any, index: number): boolean {
    let shouldFlip = false;

    this.data.forEach((element, idx) => {
      if (element !== item) {
        const nut1 = element["nutrients"].find(
          (x) => x.type === "carbohydrates"
        );
        const nut2 = item["nutrients"].find((x) => x.type === "carbohydrates");

        if (nut1 && nut2) {
          const difference = Math.abs(nut1.value - nut2.value);

          // If the values are close and the current index is greater, mark as flipped
          if (difference < 0.02 && idx < index) {
            shouldFlip = true;
          }
        }
      }
    });

    return true;
  }
  sharesSatFatValue(item: any, index: number): boolean {
    let shouldFlip = false;

    this.data.forEach((element, idx) => {
      if (element !== item) {
        const nut1 = element["nutrients"].find(
          (x) => x.type === "carbohydrates"
        );
        const nut2 = item["nutrients"].find((x) => x.type === "carbohydrates");

        if (nut1 && nut2) {
          const difference = Math.abs(nut1.value - nut2.value);

          // If the values are close and the current index is greater, mark as flipped
          if (difference < 0.02 && idx < index) {
            shouldFlip = true;
          }
        }
      }
    });

    return true;
  }
  toggleSectionToDisplay(section: number) {
    // If the same section is clicked again, close it
    this.sectionToDisplay = this.sectionToDisplay === section ? 0 : section;
  }

  getOptimalValue(kit: any, type: string) {
    const object = kit["nutrients"].find((x) => x.type === type);
    return object.value || 0;
  }
  sharesValue(item: any, type: string, index: number): boolean {
    let shouldFlip = false;

    this.data.forEach((element, idx) => {
      if (element !== item) {
        const nut1 = element["nutrients"].find((x) => x.type === type);
        const nut2 = item["nutrients"].find((x) => x.type === type);
        console.log(nut1);
        console.log(nut2);
        if (nut1 && nut2) {
          const difference = Math.abs(nut1.value - nut2.value);
          console.log(difference);
          // If the values are close and the current index is greater, mark as flipped
          if (difference < 1 && idx < index) {
            shouldFlip = true;
          }
        }
      }
    });

    return shouldFlip;
  }
}
